import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import ToggleColorMode from '../landingpage/ToggleColorMode';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from "../authentication/Firebase";
import { useNavigate } from 'react-router-dom';
import multivrselogo from "../landingpage/multivrselogo.svg";


import User from '../landingpage/User';
import getLPTheme from '../../getLPTheme';

const MobileAppAppBar = ({ mode, toggleColorMode, }) => {
  const theme = getLPTheme();

  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut()
        .then(() => {
            // Sign-out successful.
            ('User signed out');
            navigate('/signin');
        })
        .catch((error) => {
            // An error happened.
            console.error('Error signing out:', error);
        });
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
          padding: '0px',
        }}
      >
        <Container maxWidth='false'>
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              width: '100%',
              padding: '0px',
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-10px',
                px: 0,
              }}
            >
              <User theme={theme}/>
            </Box>
            <Box
              sx={{
                display: { xs: 'flex', },
                marginLeft: 'auto',
                gap: 1,
              }}
            >
               <Tooltip title="Go back to Multivrselab">
                      <IconButton onClick={() => window.open("https://www.multivrselab.com/")}>
                        <img 
                          src={multivrselogo}
                          alt="Profile"
                          style={{ width: '30px', height: '30px', borderRadius: '50%', gap : 0.5}} 
                        />
                        
                      </IconButton>
                    </Tooltip>
               
              <Tooltip title="Logout">
                <IconButton className='logoutButton' onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

MobileAppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired
};

export default MobileAppAppBar;