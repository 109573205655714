import React, {  useState, useRef } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './AppAppBar.js';
import Viewer3D from './Viewer3D.js';
import ProjectBrowser from "./ProjectBrowser.js";
import getLPTheme from '../../getLPTheme.js';

const InteriaUI = () => {
  const [mode, setMode] = useState('dark');
  const viewer3DRef = useRef(null);
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    
    setMode((prevMode) => { 
      return prevMode === 'dark' ? 'light' : 'dark'
    });
  };
  const handleFileSelected = (file) => {
    if (viewer3DRef.current) {
      viewer3DRef.current.loadFile(file);
    }
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar
        theme={LPtheme} mode={mode} toggleColorMode={toggleColorMode} onFileSelected={handleFileSelected}
      />
      <ProjectBrowser mode={mode} />
      <Viewer3D />
    </ThemeProvider>
  );
}

export default InteriaUI;
