import React, { useState, useEffect, useRef, useContext } from "react";
import "aframe";
import interia_lobby from "../landingpage/interia_lobby.jpeg";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getDownloadURL } from "firebase/storage";
import { getDoc, doc } from "firebase/firestore";
import { ref } from "firebase/storage";
import { store, storage } from "../authentication/Firebase";
import { userStorage, witAIToken } from "../constants";
import axios from "axios";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { URL, MQTTOptions } from "../constants";
import mqtt from "mqtt";
import interia_logo_no_BG from "../../icons/interiaLogo.svg";
import recording from "./recording.png";
import MenuItem from "./MenuItem";
import { AppContext } from "../../AppContext";

let ffmpeg;
let audioContext;
let analyser;
let dataArray;
let animationFrameId;
let recorder;
let chunks = [];
const VOLUME_THRESHOLD = 0; // Define the threshold as a separate variable
let source = null;

function QuestPage() {
  const {
    setSelectedID,
    selectedID,
    currentSphereNumber,
    setCurrentSphereNumber,
    isListening,
    setIsListening,
    currentSection,
    setCurrentSection,
  } = useContext(AppContext);

  const baseURL = URL.baseURL;
  const skyRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const [connectStatus, setConnectStatus] = useState(false);
  const [text, setText] = useState(
    "Please Log in to your phone and make sure the phone and this device are on the same network."
  );
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [currentlyRecording, setCurrentlyRecording] = useState(false);
  const [fs, setFs] = useState(null);
  const [currentDesignerMail, setCurrentDesignerMail] = useState(null);
  const [displaySection, setDisplaySection] = useState(null);
  const [currentDesigner, setCurrentDesigner] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [metadata, setMetadata] = useState(null);
  const [currentSphereData, setCurrentSphereData] = useState(null);
  const [showMenu, setShowMenu] = useState(true);

    useEffect(()=>{
      console.log(showMenu);
    },[showMenu]);


  useEffect(() => {
    load();
  }, [fs]);

  function load() {
    console.log("fs", fs);
    if (fs != null) {
      const keys = Object.keys(fs);
      const designerList = {};
      for (let key of keys) {
        designerList[key] = fs[key].name;
      }
      setDisplaySection(designerList);
      setCurrentSection("Designer");
    }
  }
  useEffect(() => {
    console.log("displaySection", displaySection);
  }, [displaySection]);

  // function sanitizeKeys(obj) {
  //   if (obj !== Object(obj) || Array.isArray(obj)) return obj;

  //   const sanitizedObj = {};
  //   Object.keys(obj).forEach((key) => {
  //     const sanitizedKey = key.toLowerCase().replace(/[^a-z]/g, "");
  //     sanitizedObj[sanitizedKey] = sanitizeKeys(obj[key]);
  //   });
  //   return sanitizedObj;
  // }

  function loadImage(selectedRef) {
    Array.prototype.slice
      .call(document.getElementsByTagName("a-sphere"))
      .forEach(function (item) {
        item.remove();
      });
    getDownloadURL(ref(storage, selectedRef))
      .then(function (url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.open("GET", url);
        xhr.send();
        const newSky = document.createElement("a-sky");
        newSky.setAttribute("class", "clickable");
        newSky.setAttribute("src", url);
        newSky.setAttribute("rotation", "0 -90 0");
        // Replace the existing <a-sky> element with the new one
        sceneRef.current.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;
      })
      .catch(function (error) {
        // Handle any errors
        TTS("Ask your designer to upload the image for this room!");
        console.error("Error fetching file:", error);
        const newSky = document.createElement("a-sky");
        newSky.setAttribute("class", "clickable");
        newSky.setAttribute("src", interia_lobby);
        newSky.setAttribute("rotation", "0 -90 0");
        // Replace the existing <a-sky> element with the new one
        sceneRef.current.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;
        setMetadata(null);
        setCurrentSphereNumber(0);
      });
    //replace .png with -metadata.json
    const metaDataPath = selectedRef.replace(".png", "-metadata.json");
    getDownloadURL(ref(storage, metaDataPath))
      .then((url) => {
        // Use the URL to fetch the JSON data
        return fetch(url);
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((jsonData) => {
        // Use jsonData as your JSON data
        setMetadata(jsonData);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  }

  async function getSharedProjectsCustomer(email) {
    const sharedProjectsCustomerRef = doc(
      store,
      userStorage.sharedProjectsCustomer,
      email
    );
    let sharedProjectsCustomerData = {};
    try {
      const docSnap = await getDoc(sharedProjectsCustomerRef);
      if (docSnap.exists()) {
        let editedData = docSnap.data();
        // Loop through each mail
        for (let mail of Object.keys(editedData)) {
          let userName = trimMail(mail);
          let projects = {}; // to store shared projects
          let projIDs = editedData[mail];
          const projRef = ref(
            storage,
            baseURL + "/" + mail + "/projectList.json"
          );
          // Fetch the project list data
          const downloadURL = await getDownloadURL(projRef);
          const response = await fetch(downloadURL);
          let projectListData = await response.json();
          // Loop through project IDs and add them to the projects object
          for (let projID of Object.keys(projIDs)) {
            const project = projectListData[projID];
            projects[projID] = project;
          }
          projects["name"] = userName;
          sharedProjectsCustomerData[mail] = projects;
        }
        // Log the data after it has been fully populated
        setFs(sharedProjectsCustomerData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document or project list data:", error);
      setConnectStatus(false);
    }
    return sharedProjectsCustomerData;
  }

  //mqtt code starts

  const [publicIP, setPublicIP] = useState("");
  const [privateIP, setPrivateIP] = useState("");
  const [client, setClient] = useState(null);
  const [subscribedTopics, setSubscribedTopics] = useState(new Set());
  const [currentIndex, setCurrentIndex] = useState(0);

  // Fetch the public IP address
  const fetchPublicIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setPublicIP(response.data.ip);
      console.log("Public IP address:", response.data.ip);
    } catch (error) {
      console.error("Failed to fetch public IP address:", error);
    }
  };
  // Fetch the private IP address
  const fetchPrivateIpAddress = () => {
    const rtc = new RTCPeerConnection({ iceServers: [] });
    rtc.createDataChannel("");
    rtc.createOffer().then((offer) => rtc.setLocalDescription(offer));
    rtc.onicecandidate = (event) => {
      if (event && event.candidate && event.candidate.candidate) {
        const candidate = event.candidate.candidate;
        console.log("ICE candidate:", candidate);
        const uniqueAdress = candidate.split(" ")[4];
        console.log("Unique address:", uniqueAdress);
        //remove initial space
        if (candidate) {
          setPrivateIP(uniqueAdress);
          rtc.close();
        }
      }
    };
  };

  useEffect(() => {
    if (!publicIP || !privateIP) return;

    const newClient = mqtt.connect(URL.MQTTServer, MQTTOptions);
    const topic = `${publicIP}`;

    setClient(newClient);

    newClient.on("connect", () => {
      console.log("Connected to MQTT broker via WebSocket");
      newClient.subscribe(topic + "/#", { qos: 1 }, (err) => {
        if (err) {
          console.error("Failed to subscribe to topic:", topic, err);
        } else {
          console.log("Subscribed to topic:", topic);
        }
      });
    });

    newClient.on("message", (topic, message) => {
      console.log("Received message:", message.toString(), "on topic:", topic);
      // Check for the specific message and extract the code
      const messageJSON = JSON.parse(message.toString());
      const { token, username, privateIP } = messageJSON;
      // Check if received data is already in subscribedTopics
      let found = false;
      subscribedTopics.forEach((element) => {
        if (element.username === username) {
          found = true;
        }
      });
      if (!found) {
        setSubscribedTopics((prevTopics) => [
          ...prevTopics,
          { token, username, privateIP },
        ]);
      }
    });

    newClient.on("error", (err) => {
      console.log("Connection error:", err);
    });

    if (connectStatus && subscribedTopics.length > 0) {
      console.log("subscribedTopics", subscribedTopics);
      TTS("Connection established");
      //sign in with custom token
      const auth = getAuth();
      const token = subscribedTopics[currentIndex].token;
      console.log("token", token);
      signInWithCustomToken(auth, token)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);

          getSharedProjectsCustomer(user.email);
          newClient.publish(
            publicIP,
            JSON.stringify({
              stop: subscribedTopics[currentIndex].privateIP,
            }),
            { qos: 1 },
            (err) => {
              if (err) {
                console.error("Failed to publish message:", err);
              } else {
                console.log(
                  "Published message:",
                  subscribedTopics[currentIndex].username,
                  "on topic:",
                  publicIP
                );
              }
            }
          );
          // Handle beforeunload to publish a message
          const handleBeforeUnload = (event) => {
            try {
              newClient.publish(
                `${publicIP}/${subscribedTopics[currentIndex].privateIP}`, // Adjust the topic as needed
                JSON.stringify({ message: "SimutopiaMobileReload" }), // Customize the message as needed
                { qos: 1 },
                (err) => {
                  if (err) {
                    console.error(
                      "Failed to publish before unload message:",
                      err
                    );
                  } else {
                    console.log("Published before unload message");
                  }
                }
              );
            } catch (err) {
              console.log("error in handleBeforeUnload", err);
            }
          };

          window.addEventListener("beforeunload", handleBeforeUnload);
          newClient.subscribe(
            topic + "/" + subscribedTopics[currentIndex].privateIP,
            (err) => {
              if (err) {
                console.error(
                  "Failed to subscribe to topic:",
                  subscribedTopics[currentIndex].privateIP,
                  err
                );
              } else {
                console.log(
                  "Subscribed to topic:",
                  subscribedTopics[currentIndex].privateIP
                );
              }
            }
          );
          newClient.unsubscribe(topic + "/#", (err) => {
            if (err) {
              console.error("Failed to unsubscribe from topic:", topic, err);
            } else {
              console.log("Unsubscribed from topic:", topic);
            }
          });
        })
        .catch((error) => {
          console.log("error occured:::::::", error);
          //window.location.reload();
          setConnectStatus(false);
        });
    }

    // Clean up
    return () => {
      if (newClient) {
        newClient.end();
      }
    };
  }, [publicIP, privateIP, connectStatus, subscribedTopics]);

  //TTS & STT starts

  const TTS = async (text) => {
    try {
      // Send POST request to Wit.ai API
      console.log("text", text);
      console.log("witAIToken", witAIToken);

      const response = await axios.post(
        "https://api.wit.ai/synthesize?v=20240304",
        { q: text, voice: "Rebecca" },
        {
          headers: {
            Authorization: "Bearer " + witAIToken, // Bearer token for authorization
            "Content-Type": "application/json",
            Accept: "audio/pcm16", // Correct MIME type for the request
          },
          responseType: "arraybuffer", // We need the response as a raw binary stream
        }
      );
      // Step 1: Create an AudioContext
      const audioContext = new window.AudioContext();
      // Step 2: Convert PCM16 Data to Float32
      function convertPCM16ToFloat32(buffer) {
        const dataView = new DataView(buffer);
        const float32Array = new Float32Array(buffer.byteLength / 2);
        for (let i = 0; i < float32Array.length; i++) {
          const int16 = dataView.getInt16(i * 2, true); // little-endian
          float32Array[i] = int16 / 32768; // convert to float32
        }
        return float32Array;
      }
      const sampleRate = 23500; // adjust to your audio data's sample rate
      const numberOfChannels = 1; // adjust to your audio data's number of channels
      const audioDataFloat32 = convertPCM16ToFloat32(response.data);
      const audioBuffer = audioContext.createBuffer(
        numberOfChannels,
        audioDataFloat32.length,
        sampleRate
      );
      audioBuffer.copyToChannel(audioDataFloat32, 0);
      if (source != null) source.stop();
      source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start(0);

      console.log("Playing audio", text);
    } catch (error) {
      console.error("Error fetching audio:", error);
    }
  };

  const loadFFmpeg = async () => {
    if (ffmpegLoaded == false) {
      console.log("loading ffmpeg");

      const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
      ffmpeg = new FFmpeg();
      ffmpeg.on("log", ({ message }) => {
        // console.log(message);
      });
      ffmpeg
        .load({
          coreURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.js`,
            "text/javascript"
          ),
          wasmURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.wasm`,
            "application/wasm"
          ),
        })
        .then(() => {
          console.log("FFmpeg loaded");
          setFfmpegLoaded(true);
        });
    }
  };

  useEffect(() => {
    skyRef.current.setAttribute("src", interia_lobby);
    fetchPublicIpAddress();
    fetchPrivateIpAddress();
    loadFFmpeg();
  }, []);

  if (!window.AFRAME.components["right-controllor"]) {
    window.AFRAME.registerComponent("right-controllor", {
      init: function () {
        // Swapped gripdown with triggertouchstart/triggertouchend
        this.el.addEventListener("gripdown", (e) => {
          setIsListening(true);
          // startMonitoring();
        });
        this.el.addEventListener("gripup", (e) => {
          setIsListening(false);
          // stopMonitoring();
        });
        this.el.addEventListener("triggertouchstart", (e) => {
          const intersectedEls = e.target.components.raycaster.intersectedEls;
          if (intersectedEls.length > 0) {
            const intersectedEl = intersectedEls[0];
            console.log("clicked");
            if (intersectedEl.classList.contains("menu")) {
              var uniqueID = intersectedEl.getAttribute("uniqueID");
              setSelectedID(uniqueID);
              console.log(uniqueID);
            }
            if (intersectedEl.classList.contains("metadata")) {
              const sphereNumber = intersectedEl.getAttribute("sphereNumber");
              setCurrentSphereNumber(sphereNumber);
            }
          }
        });
      },
    });
  }

  useEffect(() => {
    if (isListening == true) {
      startMonitoring();
    } else if (isListening == false) {
      stopMonitoring();
    }
  }, [isListening]);

  const startMonitoring = async () => {
    if (currentlyRecording || !ffmpegLoaded) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      chunks = [];
      recorder = new MediaRecorder(stream);

      recorder.onstart = () => {
        console.log("Recording started");
        setCurrentlyRecording(true);
      };

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = async () => {
        console.log("Recording stopped");
        const audioBlob = new Blob(chunks, { type: "audio/webm" });
        console.log(audioBlob);
        try {
          if (audioBlob.size == 0) {
            setCurrentlyRecording(false);
            setIsListening(false);
            return;
          }
          await convertWebmToMp3(audioBlob);
        } catch (err) {
          console.error(err.message);
        } finally {
          chunks = [];
          setCurrentlyRecording(false);
          setIsListening(false);
        }
      };

      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const mediaStreamSource = audioContext.createMediaStreamSource(stream);
      analyser = audioContext.createAnalyser();
      mediaStreamSource.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      dataArray = new Uint8Array(bufferLength);
      draw();

      recorder.start();
    } catch (err) {
      console.error("Error accessing the microphone:", err);
    }
  };

  const stopMonitoring = () => {
    if (recorder && recorder.state === "recording") {
      recorder.stop();
      setCurrentlyRecording(false);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      if (audioContext) {
        audioContext.close();
      }
    }
  };

  const draw = () => {
    if (!currentlyRecording) return;

    analyser.getByteTimeDomainData(dataArray);

    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const v = dataArray[i] / 128.0;
      sum += Math.abs(v - 1);
    }

    const volume = (sum / dataArray.length) * 100;

    if (volume > VOLUME_THRESHOLD) {
      console.log("Speaking");
      document.body.classList.add("speaking");
      if (recorder.state === "recording") {
        setTimeout(() => {
          if (recorder && recorder.state === "recording") {
            recorder.stop();
            setCurrentlyRecording(false);
            document.body.classList.remove("speaking");
            console.log("Recording stopped");
          }
        }, 3000);
      } else {
        setCurrentlyRecording(true);
        recorder.start();
      }
    }

    animationFrameId = requestAnimationFrame(draw);
  };

  const convertWebmToMp3 = async (webmBlob) => {
    try {
      //check for errors in webmBlob
      if (webmBlob.size == 0) {
        console.log("webmBlob is empty");
        // checkSpeechCommands("");
        return;
      }
      const webmFile = new File([webmBlob], "input.webm");
      const file = await fetchFile(webmFile);
      await ffmpeg.writeFile("input.webm", file);
      await ffmpeg.exec(["-i", "input.webm", "output.mp3"]);
      const mp3Data = await ffmpeg.readFile("output.mp3");
      const mp3Blob = new Blob([mp3Data.buffer], { type: "audio/mp3" });
      sendAudioToWit(mp3Blob);
      await ffmpeg.deleteFile("input.webm");
      await ffmpeg.deleteFile("output.mp3");
      //clear memory
      return mp3Blob;
    } catch (err) {
      console.error(err);
      setCurrentlyRecording(false);
      setIsListening(false);
      ffmpeg.terminate();
      ffmpeg = null;
      setFfmpegLoaded(false);
      await loadFFmpeg();
      return new Blob();
    }
  };

  const sendAudioToWit = async (audioBlob) => {
    try {
      const response = await fetch("https://api.wit.ai/dictation?v=20240304", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${witAIToken}`,
          "Content-Type": "audio/mpeg3",
        },
        body: audioBlob,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const text = await response.text();
      const jsonObjects = text.toString().split('"is_final": true,');
      const finalTranscriptionPart =
        jsonObjects[jsonObjects.length - 1].split('"text":')[1];
      const finalTranscription = finalTranscriptionPart.split(",\n")[0];
      console.log(finalTranscription);
      checkSpeechCommands(finalTranscription);
    } catch (error) {
      console.error("Error sending audio to Wit.ai:", error);
    }
  };

  function checkSpeechCommands(finalTranscription) {
    let theMatch = null;
    finalTranscription = finalTranscription.toLowerCase();
    //trim all special characters
    finalTranscription = finalTranscription.replace(/[^a-zA-Z0-9 ]/g, "");
    finalTranscription = textToNumber(finalTranscription);
    console.log("finalTranscription", finalTranscription);

    if (!connectStatus && finalTranscription.toLowerCase().includes("yes")) {
      console.log("matches with Yes");
      setSelectedID("Yes");
      return;
    }
    if (!connectStatus && finalTranscription.toLowerCase().includes("no")) {
      console.log("matches with No");
      setSelectedID("No");
      return;
    }

    if (
      (theMatch =
        finalTranscription.match(/open\s+designer\s+menu\s*(.*)/i) ||
        finalTranscription.match(/open\s+designers\s+menu\s*(.*)/i) ||
        finalTranscription.match(/open\s+designer\s+menu\s*(.*)/i) ||
        finalTranscription.match(/open\s+designers\s+menu\s*(.*)/i)) ||
      finalTranscription.match(/showed.*?designer.*?(.*)/i) ||
      finalTranscription.match(/showed.*?designer.*?(.*)/i) ||
      finalTranscription.match(/shown.*?designer.*?(.*)/i) ||
      finalTranscription.match(/shean.*?designer.*?(.*)/i) ||
      finalTranscription.match(/shawn.*?designer.*?(.*)/i) ||
      finalTranscription.match(/show.*?designer.*?(.*)/i) ||
      finalTranscription.match(/sure.*?designer.*?(.*)/i) ||
      finalTranscription.match(/open.*?designer.*?(.*)/i) ||
      finalTranscription.match(/opened.*?designer.*?(.*)/i) ||
      finalTranscription.match(/pen.*?designer.*?(.*)/i)
    ) {
      // Show designer menu
      showDesignersMenu();
      return;
    } else if (
      (theMatch =
        finalTranscription.match(/showed.*?project.*?(.*)/i) ||
        finalTranscription.match(/showed.*?project.*?(.*)/i) ||
        finalTranscription.match(/shown.*?project.*?(.*)/i) ||
        finalTranscription.match(/shean.*?project.*?(.*)/i) ||
        finalTranscription.match(/shawn.*?project.*?(.*)/i) ||
        finalTranscription.match(/show.*?project.*?(.*)/i) ||
        finalTranscription.match(/sure.*?project.*?(.*)/i) ||
        finalTranscription.match(/open.*?project.*?(.*)/i) ||
        finalTranscription.match(/opened.*?project.*?(.*)/i) ||
        finalTranscription.match(/pen.*?project.*?(.*)/i))
    ) {
      // Show project menu
      showProjectsMenu();
      return;
    } else if (
      (theMatch =
        finalTranscription.match(/select.*?room.*?(.*)/i) ||
        finalTranscription.match(/selected.*?room.*?(.*)/i) ||
        finalTranscription.match(/go.*?to.*?room.*?(.*)/i) ||
        finalTranscription.match(/go.*?two.*?room.*?(.*)/i) ||
        finalTranscription.match(/choose.*?room.*?(.*)/i) ||
        finalTranscription.match(/show.*?room.*?(.*)/i) ||
        finalTranscription.match(/open.*?room.*?(.*)/i) ||
        finalTranscription.match(/opened.*?room.*?(.*)/i) ||
        finalTranscription.match(/pen.*?room.*?(.*)/i))
    ) {
      console.log("matches with room", finalTranscription);

      showRoomMenu();
      return;
    }
    else if (
      (theMatch =
        finalTranscription.match(/showed.*?menu.*?(.*)/i) ||
        finalTranscription.match(/shown.*?menu.*?(.*)/i) ||
        finalTranscription.match(/shean.*?menu.*?(.*)/i) ||
        finalTranscription.match(/shawn.*?menu.*?(.*)/i) ||
        finalTranscription.match(/show.*?menu.*?(.*)/i) ||
        finalTranscription.match(/sure.*?menu.*?(.*)/i) ||
        finalTranscription.match(/open.*?menu.*?(.*)/i) ||
        finalTranscription.match(/opened.*?menu.*?(.*)/i) ||
        finalTranscription.match(/pen.*?menu.*?(.*)/i) ||
        finalTranscription.match(/show\s*me\s*new/i)) // Added "show me new" match
    ) {
      setShowMenu(true);
      return;
    }
    
    else {
      const match = findNameInStringFromArray(
        finalTranscription,
        Object.values(displaySection)
      );
      //search the match in displayseciton and get the id of the match
      if (match) {
        const key = Object.keys(displaySection).find(
          (key) => displaySection[key] === match
        );
        console.log("key", key);
        setSelectedID(key);
        return;
      } else {
        TTS("Sorry, I didn't get that. Please try again.");
      }
    }
  }

  //Funciton to find any word in a string that matches to given array
  function findNameInStringFromArray(str, arr) {
    const foundName = arr.find((name) =>
      str
        .replace(/\s/g, "")
        .toLowerCase()
        .includes(name.replace(/\s/g, "").toLowerCase())
    );
    if (foundName) {
      console.log("found this match:::" + foundName);
      return foundName;
    } else {
      console.log("No match found");
      return null;
    }
  }

  function showDesignersMenu() {
    if (connectStatus) {
      load();
      TTS("Showing designers");
      console.log("Setting the current section to designer");
    } else {
      TTS("Please connect to your mobile device first");
    }
  }

  function showProjectsMenu() {
    if (connectStatus && currentDesigner != null) {
      const projectsList = fs[currentDesigner];
      const projectList = {};
      if (projectsList != null || projectsList != undefined)
        for (let key of Object.keys(projectsList)) {
          if (key != "name")
            if (typeof projectsList[key] === "object")
              //check if projectList[key] is an object
              projectList[key] = projectsList[key].name;
        }
      setDisplaySection(projectList);
      setSelectedID(null);
      setCurrentSection("Project");
      TTS("Showing projects");
      console.log("Setting the current section to project");
    } else if (!connectStatus) {
      TTS("Please connect to your mobile device first");
    } else if (currentDesigner == null) {
      TTS("Please select a designer first");
    }
  }

  function showRoomMenu() {
    if (connectStatus && currentProject != null && currentDesigner != null) {
      setMetadata(null);
      setCurrentSphereNumber(0);
      setCurrentSphereData(null);
      const roomList = {};
      const roomData = fs[currentDesigner][currentProject];
      for (let key of Object.keys(roomData["rooms"])) {
        roomList[key] = roomData["rooms"][key].name;
      }
      setDisplaySection(roomList);
      setSelectedID(null);
      setCurrentSection("Room");
      TTS("Showing rooms");
      console.log("Setting the current section to room");
    } else if (!connectStatus) {
      TTS("Please connect to your mobile device first");
    } else if (currentProject == null) {
      TTS("Please select a project first");
    }
  }

  function textToNumber(text) {
    const units = {
      zero: 0,
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      five: 5,
      six: 6,
      seven: 7,
      eight: 8,
      nine: 9,
    };

    const teens = {
      ten: 10,
      eleven: 11,
      twelve: 12,
      thirteen: 13,
      fourteen: 14,
      fifteen: 15,
      sixteen: 16,
      seventeen: 17,
      eighteen: 18,
      nineteen: 19,
    };

    const tens = {
      twenty: 20,
      thirty: 30,
      forty: 40,
      fifty: 50,
      sixty: 60,
      seventy: 70,
      eighty: 80,
      ninety: 90,
    };

    const scales = {
      hundred: 100,
      thousand: 1000,
      million: 1000000,
    };

    function parseNumber(words) {
      let result = 0;
      let current = 0;

      for (let word of words) {
        if (units[word] !== undefined) {
          current += units[word];
        } else if (teens[word] !== undefined) {
          current += teens[word];
        } else if (tens[word] !== undefined) {
          current += tens[word];
        } else if (scales[word] !== undefined) {
          current *= scales[word];
          result += current;
          current = 0;
        }
      }

      return result + current;
    }

    const words = text.toLowerCase().split(" ");
    let numberArray = [];
    let buffer = [];

    for (let word of words) {
      if (
        units[word] !== undefined ||
        teens[word] !== undefined ||
        tens[word] !== undefined ||
        scales[word] !== undefined
      ) {
        buffer.push(word);
      } else {
        if (buffer.length > 0) {
          numberArray.push(parseNumber(buffer).toString());
          buffer = [];
        }
        numberArray.push(word);
      }
    }

    if (buffer.length > 0) {
      numberArray.push(parseNumber(buffer).toString());
    }

    return numberArray.join(" ");
  }

  function trimMail(mail) {
    var trimmedmail = "";
    if (mail == null || mail == undefined) return "";
    if (mail.indexOf(".") < mail.indexOf("@")) {
      trimmedmail = mail.split(".")[0];
    } else {
      trimmedmail = mail.split("@")[0];
    }
    return trimmedmail;
  }

  //metadata code starts

  function showSpheres(data) {
    const camera = document.querySelector("[camera]");
    const cameraPosition = new window.THREE.Vector3();
    camera.object3D.getWorldPosition(cameraPosition);

    for (var key in data) {
      if (isNaN(key)) {
        continue;
      }

      var textEl = document.createElement("a-text");
      var planeEl = document.createElement("a-plane");

      if (textEl && planeEl) {
        console.log("key", key);
        console.log("currentSphereNumber", currentSphereNumber);
        setMultipleAttributes(textEl, {
          position: data[key].intersection,
          value: key,
          align: "center",
          color: "white",
          width: 300,
          // scale: "3 3 3",
          class: "raycastable metadata",
          metadataKeys: true,
        });
        // Set attributes for the plane
        setMultipleAttributes(planeEl, {
          position: data[key].intersection,
          width: 20,
          height: 20,
          color: "black",
          sphereNumber: key,
          transparent: true,
          opacity: 0.8,
          spheres: true,
          class: "raycastable metadata",
        });
        // Calculate direction vector from camera to intersection
        const intersectionPosition = new window.THREE.Vector3(
          data[key].intersection.x,
          data[key].intersection.y,
          data[key].intersection.z
        );
        const direction = new window.THREE.Vector3()
          .subVectors(intersectionPosition, cameraPosition)
          .normalize();

        // Move text and plane slightly inward toward the user
        const inwardDistanceText = -200;
        const inwardDistancePlane = -15;
        const upwardOffset = 20;
        const downwardOffset = 30;

        const inwardPositionText = intersectionPosition
          .clone()
          .add(direction.clone().multiplyScalar(inwardDistanceText))
          .add(new window.THREE.Vector3(0, upwardOffset, 0));

        const inwardPositionPlane = intersectionPosition
          .clone()
          .add(direction.clone().multiplyScalar(inwardDistancePlane))
          .add(new window.THREE.Vector3(0, -downwardOffset, 0));

        // Set positions of text and plane elements
        textEl.object3D.position.copy(inwardPositionText);
        planeEl.object3D.position.copy(inwardPositionPlane);

        // Use lookAt to orient the text and plane elements towards the camera
        textEl.object3D.lookAt(cameraPosition);
        planeEl.object3D.lookAt(cameraPosition);

        if (sceneRef.current) {
          sceneRef.current.appendChild(planeEl);
          sceneRef.current.appendChild(textEl);
        }
      }
    }
    if (!window.AFRAME.components["spheres"]) {
      window.AFRAME.registerComponent("spheres", {
        init: function () {
          this.el.addEventListener("click", (e) => {
            const intersectedEl = e.target;
            const sphereNumber = intersectedEl.getAttribute("sphereNumber");
            console.log("sphereNumber", sphereNumber);
            setCurrentSphereNumber(sphereNumber);
          });
          //hover effect
          this.el.addEventListener("mouseenter", (e) => {
            const intersectedEl = e.target;
            intersectedEl.setAttribute("color", "red");
          });
          this.el.addEventListener("mouseleave", (e) => {
            const intersectedEl = e.target;
            intersectedEl.setAttribute("color", "black");
          });
        },
      });
    }
  }
  //remove all spheres
  function removeSpheres() {
    var elements = document.querySelectorAll(
      "a-text[metadataKeys], a-plane[spheres]"
    );
    elements.forEach((el) => {
      el.parentNode.removeChild(el);
    });
  }

  useEffect(() => {
    if (metadata && metadata[currentSphereNumber]) {
      //change that sphere color
      console.log("trying to change the color of number", currentSphereNumber);
      var selectedSphere = document.querySelector(
        `a-text[value="${currentSphereNumber}"]`
      );
      console.log("selectedSphere", selectedSphere);
      //change  all spheres color to white
      var spheres = document.querySelectorAll("a-text[metadataKeys]");
      spheres.forEach((sphere) => {
        sphere.setAttribute("color", "white");
      });
      if (selectedSphere) {
        console.log("selectedSphere", selectedSphere);
        selectedSphere.setAttribute("color", "red");
      }
      var met = formatMetadata(metadata[currentSphereNumber]);
      setCurrentSphereData(met);
    }
  }, [currentSphereNumber]);

  function clearSelectedSphereWithNumber(number) {
    if (metadata && metadata[number]) {
      //change that sphere color
      console.log("trying to change the color of number", number);
      var selectedSphere = document.querySelector(`a-text[value="${number}"]`);
      console.log("selectedSphere", selectedSphere);
      if (selectedSphere) {
        console.log("selectedSphere", selectedSphere);
        selectedSphere.setAttribute("color", "white");
      }
    }
  }

  const setMultipleAttributes = (el, attributes) => {
    for (var attr in attributes) {
      el.setAttribute(attr, attributes[attr]);
    }
  };

  function formatMetadata(data) {
    var met = "";
    for (var key in data) {
      if (key !== "intersection") {
        met += key + ": " + data[key] + "\n";
      }
    }
    return met;
  }

  useEffect(() => {
    if (metadata) {
      showSpheres(metadata);
    }
    return () => {
      removeSpheres();
    };
  }, [metadata]);

  //menu starts

  const columnWidth = 4; // Width of each column
  const columnGap = 5; // Gap between columns
  const maxItemsPerColumn = 3; // Maximum number of items per column
  const maxItemsPerPage = 5; // Maximum number of items to display per page
  const totalItems = displaySection ? Object.keys(displaySection).length : 0; // Total number of items
  const totalPages = Math.ceil(totalItems / maxItemsPerPage); // Total number of pages
  const menuWidth =
    Math.ceil(maxItemsPerPage / maxItemsPerColumn) * (columnWidth + columnGap) -
    columnGap; // Calculate the total menu width
  const centerOffset = menuWidth / 2; // Calculate the center offset
  const itemHeight = 2; // Height of each item
  const itemGap = 1.5; // Gap between items

  const handlePrevious = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage));
  };

  const handleNext = () => {
    const totalPages = Math.ceil(
      Object.keys(displaySection).length / maxItemsPerPage
    );
    console.log("totalPages", totalPages);

    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handleBack = () => {
    console.log("currentSection inside handleBack", currentSection);

    if (currentSection == "Project") {
      showDesignersMenu();
    }
    if (currentSection == "Room") {
      showProjectsMenu();
    }
    if (currentSection == "Views") {
      showRoomMenu();
    }
  };

  useEffect(() => {
    setCurrentSphereData(null);
    setCurrentSphereData(null);
    if (selectedID == "Yes") {
      setConnectStatus(true);
      setSelectedID(null);
      return;
    }
    if (selectedID == "No") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % subscribedTopics.length);
      setSelectedID(null);
      return;
    }
    if (selectedID == "Next") {
      handleNext();
      setSelectedID(null);
      return;
    }
    if (selectedID == "Previous") {
      handlePrevious();
      setSelectedID(null);
      return;
    }
    if (selectedID == "Back") {
      handleBack();
      setSelectedID(null);
      return;
    }
    if(selectedID == "HideMenu") {
      setShowMenu(false);
      setSelectedID(null);
      return;
    }

    if (currentSection == "Designer" && selectedID != null) {
      console.log("inside designer", selectedID);

      setCurrentDesignerMail(selectedID);
      setCurrentDesigner(selectedID);
      setCurrentSection("Project");
      const projectsList = fs[selectedID];
      const projectList = {};
      if (projectsList != null || projectsList != undefined)
        for (let key of Object.keys(projectsList)) {
          if (key != "name")
            if (typeof projectsList[key] === "object")
              //check if projectList[key] is an object
              projectList[key] = projectsList[key].name;
        }
      setDisplaySection(projectList);
      TTS("Selected designer " + fs[selectedID].name);
      setSelectedID(null);
      return;
    }
    if (currentSection == "Project" && selectedID != null) {
      console.log("inside project", selectedID);

      setCurrentProject(selectedID);
      setCurrentSection("Room");
      const roomList = {};
      const roomData = fs[currentDesignerMail][selectedID];
      for (let key of Object.keys(roomData["rooms"])) {
        roomList[key] = roomData["rooms"][key].name;
      }
      setDisplaySection(roomList);
      TTS("Selected project " + fs[currentDesignerMail][selectedID].name);
      setSelectedID(null);
      return;
    }
    if (currentSection == "Room" && selectedID != null) {
      console.log("inside room", selectedID);

      setCurrentRoom(selectedID);
      setCurrentSection("Views");
      console.log("currentDesignerMail", currentDesignerMail);
      console.log("currentProject", currentProject);
      console.log("currentRoom", currentRoom);
      console.log("selectedID", selectedID);
      console.log(fs[currentDesignerMail][currentProject]["rooms"]);

      const roomData =
        fs[currentDesignerMail][currentProject]["rooms"][selectedID]["views"];
      const roomList = {};
      for (let key of Object.keys(roomData)) {
        roomList[key] = roomData[key];
      }
      setDisplaySection(roomList);
      TTS(
        "Selected room " +
        fs[currentDesignerMail][currentProject]["rooms"][selectedID].name
      );
      setSelectedID(null);
      return;
    }
    if (currentSection == "Views" && selectedID != null) {
      console.log("inside views", selectedID);

      const ref =
        baseURL +
        "/" +
        currentDesignerMail +
        "/" +
        currentProject +
        "/" +
        currentRoom +
        "/" +
        selectedID +
        ".png";
      const selectedViewElement = document.querySelector(
        `[uniqueID="${selectedID}"]`
      );
      console.log("selectedViewElement", selectedViewElement);
      if (selectedViewElement)
        selectedViewElement.setAttribute("color", "grey");
      loadImage(ref);
      TTS("Selected view " + fs[currentDesignerMail][currentProject]["rooms"][currentRoom]["views"][selectedID]);
      return;
    }
  }, [selectedID]);

  const calculateWidth = (text) => text.length * 0.5; // Adjust scaling factor as needed
  const calculateHeight = (text) => {
    const lines = text.split("\n").length;
    return lines * 1.5; // Adjust scaling
  }; // Fixed height for all items
  const Menu = ({ displaySection }) => {
    // Calculate the items to display for the current page
    const startIndex = currentPage * maxItemsPerPage;
    const endIndex = Math.min(startIndex + maxItemsPerPage, totalItems);
    const currentItems = displaySection
      ? Object.entries(displaySection).slice(startIndex, endIndex)
      : []; // Use Object.entries to get key-value pairs
    const titleColor =
      currentSection == "Designer"
        ? "blue"
        : currentSection == "Project"
          ? "green"
          : currentSection == "Room"
            ? "red"
            : currentSection == "Views"
              ? "yellow"
              : "white";
    // Step 1: Calculate all widths and find the maximum
    const allWidths = currentItems.map(([_, value]) => calculateWidth(value));
    var maxWidth = Math.max(...allWidths);
    maxWidth = Math.max(maxWidth + 1, 4);

    return (
      <a-entity position="0 -4 -5">
        {/* Base entity positioned at origin */}
        {displaySection && currentSection != null && (
          <>
            {/* Title positioned relatively */}
            <MenuItem
              text={currentSection + " List"}
              position="0 8 -20" // Adjusted position for visibility
              width="8"
              height="2"
              textWidth={20}
              setSelectedID={setSelectedID}
              selectable={false}
              primaryTextColor={titleColor}
              primaryPlaneColor={"#42a5f5"}
              secondaryTextColor={titleColor}
              secondaryPlaneColor={titleColor}
            />
            {currentSection != "Designer" && (
              <MenuItem
                className="menu raycastable"
                uniqueID={"Back"}
                text="<"
                position={`${-centerOffset - 1} 8 -20`} // Adjusted position for Previous button
                width="1"
                height="2"
                textWidth={20}
                setSelectedID={setSelectedID}
                selectable={true}
                primaryTextColor={"black"}
                primaryPlaneColor={"#42a5f5"}
                secondaryTextColor={"white"}
                secondaryPlaneColor={"black"}
              />
            )}
            {/* Menu Items */}
            {currentItems &&
              currentItems.map(([key, value], index) => {
                const columnIndex = Math.floor(index / maxItemsPerColumn); // Column calculation
                const rowIndex = index % maxItemsPerColumn; // Row calculation
                const xPos =
                  columnIndex * (columnWidth + columnGap) - centerOffset; // X position
                const yPos = 5 - rowIndex * (itemHeight + itemGap); // Y position

                return (
                  <MenuItem
                    className="menu raycastable"
                    key={index}
                    uniqueID={key}
                    text={value} // Display both the key and value
                    position={`${xPos} ${yPos} -20`} // Z-position set to -20 for visibility
                    width={maxWidth} // Use the maximum calculated width
                    height={itemHeight}
                    textWidth={20}
                    setSelectedID={setSelectedID}
                    selectable={true}
                    primaryTextColor={"black"}
                    primaryPlaneColor={"#42a5f5"}
                    secondaryTextColor={"white"}
                    secondaryPlaneColor={"black"}
                  />
                );
              })}
            {Object.entries(currentItems).length == 0 && (
              <MenuItem
                text={`No items to display\nPlease ask designer ${fs[currentDesigner].name} to share`}
                position="0 4 -20"
                width={calculateWidth(
                  `Please ask designer  ${fs[currentDesigner].name} to share`
                )}
                height="3"
                textWidth={20}
                setSelectedID={setSelectedID}
                selectable={false}
                primaryTextColor={"black"}
                primaryPlaneColor={"#42a5f5"}
                secondaryTextColor={"white"}
                secondaryPlaneColor={"black"}
              />
            )}

            {/* Navigation Buttons */}
            {currentPage > 0 && (
              <MenuItem
                uniqueID={"Previous"}
                text="Previous"
                position={`${-centerOffset - 2} -5 -20`} // Adjusted position for Previous button
                width="4"
                height="2"
                textWidth={20}
                setSelectedID={setSelectedID}
                selectable={true}
                className="menu raycastable"
                primaryTextColor={"black"}
                primaryPlaneColor={"#42a5f5"}
                secondaryTextColor={"white"}
                secondaryPlaneColor={"black"}
              />
            )}

              <MenuItem
                uniqueID={"HideMenu"}
                text="Hide Menu"
                position={`${centerOffset} -5 -20`}
                width="5"
                height="2"
                textWidth={20}
                setSelectedID={setSelectedID}
                selectable={true}
                className="menu raycastable"
                primaryTextColor={"black"}
                primaryPlaneColor={"#42a5f5"}
                secondaryTextColor={"white"}
                secondaryPlaneColor={"black"}
              />

            {currentPage < totalPages - 1 && (
              <MenuItem
                uniqueID={"Next"}
                text="Next"
                position={`${centerOffset + 2} -5 -20`} // Adjusted position for Next button
                width="4"
                height="2"
                textWidth={20}
                setSelectedID={setSelectedID}
                selectable={true}
                className="menu raycastable"
                primaryTextColor={"black"}
                primaryPlaneColor={"#42a5f5"}
                secondaryTextColor={"white"}
                secondaryPlaneColor={"black"}
              />
            )}
          </>
        )}
      </a-entity>
    );
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {/*to remove starts */}
      <button
        onMouseDown={startMonitoring}
        onMouseUp={stopMonitoring}
        style={{ position: "absolute", top: 0, left: 0, zIndex: 999 }}
      >
        Press and Hold to Record
      </button>
      {/* to remove ends */}
      <a-scene
        id="scene"
        scene
        ref={sceneRef}
        link-controls
        vr-mode-ui
        cursor="rayOrigin: mouse; fuse: false"
        raycaster="objects: .raycastable"
        style={{ width: "100%", height: "100%" }}
      >
        <a-camera
          id="camera"
          ref={cameraRef}
          rotation-reader
          camera
          wasd-controls="acceleration:0"
        >
          {Array.isArray(subscribedTopics) && !connectStatus && (
            <>
              <MenuItem
                text={`Do you want to connect to ${trimMail(
                  subscribedTopics[currentIndex].username
                )}?`}
                position="0 3 -20"
                width="20"
                height="2"
                textWidth={20}
                setSelectedID={setSelectedID}
                selectable={false}
                primaryTextColor={"black"}
                primaryPlaneColor={"#42a5f5"}
                secondaryTextColor={"white"}
                secondaryPlaneColor={"black"}
              />
              <MenuItem
                uniqueID="Yes"
                text="Yes"
                position="-3 -2 -20"
                width="4"
                height="2"
                textWidth={20}
                setSelectedID={setSelectedID}
                selectable={true}
                className={"menu raycastable"}
                primaryTextColor={"black"}
                primaryPlaneColor={"#42a5f5"}
                secondaryTextColor={"white"}
                secondaryPlaneColor={"black"}
              />
              <MenuItem
                uniqueID="No"
                text="No"
                position="2 -2 -20"
                width="4"
                height="2"
                textWidth={20}
                setSelectedID={setSelectedID}
                selectable={true}
                className={"menu raycastable"}
                primaryTextColor={"black"}
                primaryPlaneColor={"#42a5f5"}
                secondaryTextColor={"white"}
                secondaryPlaneColor={"black"}
              />
            </>
          )}
          {displaySection && showMenu && <Menu displaySection={displaySection} />}
          {currentSphereData && (
            <MenuItem
              text={currentSphereData}
              position="0 -9 -20"
              width={calculateWidth(currentSphereData)}
              height={calculateHeight(currentSphereData)}
              textWidth={20}
              uniqueID={currentSphereNumber}
              setSelectedID={setSelectedID}
              selectable={false}
              primaryTextColor={"black"}
              primaryPlaneColor={"#42a5f5"}
              secondaryTextColor={"white"}
              secondaryPlaneColor={"black"}
            />
          )}
        </a-camera>
        <a-sky ref={skyRef} rotation="0 -90 0"></a-sky>
        <a-entity
          right-controllor
          oculus-touch-controls="hand: right"
          id="right-controller"
          raycaster="objects: .menu, .metadata, .navigate; far: 10000;showLine: true;direction: -0.5 -1 -2;"
        ></a-entity>
        <style>
          {`
  .a-enter-vr-button {
    height: 100px !important;
    width: 100px !important;
  }
`}
        </style>
      </a-scene>
    </div>
  );
}

export default QuestPage;
