import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, storageRef, store, storage } from "../authentication/Firebase";
import { doc, setDoc } from "firebase/firestore";
import { userStorage } from "../constants";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import InteriaLogo from "../../icons/interiaLogo.svg";
import { ref, uploadString } from "firebase/storage";
import { URL } from "../constants";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/" style={{ fontFamily: "Audiowide" }}>
        Interia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignUp() {
  const [userType, setUserType] = React.useState("Customer");
  const [error, setError] = React.useState(""); // State to hold authentication error
  const [darkMode, setDarkMode] = React.useState(() => {
    // Retrieve the mode from localStorage and return the appropriate boolean
    return localStorage.getItem("theme") === "dark";
  });

  React.useEffect(() => {
    // Update the localStorage whenever darkMode changes
    localStorage.setItem("theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const navigate = useNavigate();
  const firebaseAuth = auth;

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  const handleThemeToggle = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    createUserWithEmailAndPassword(
      firebaseAuth,
      data.get("email"),
      data.get("password")
    )
      .then((userCredential) => {
        const user = userCredential.user;
        const userProfileData = {
          firstname: data.get("firstname"),
          lastname: data.get("lastname"),
          email: data.get("email"),
          userType: data.get("userType"),
          organization: data.get("organization"),
        };
        localStorage.setItem("userfirstname", data.get("firstname"));
        localStorage.setItem("userlastname", data.get("lastname"));
        localStorage.setItem("email", data.get("email"));
        localStorage.setItem("Organization", data.get("organization"));
        const userProfile = doc(store, userStorage.users, user.uid);
        setDoc(userProfile, userProfileData)
          .then((docRef) => {})
          .catch((error) => {
            const errorMessage = error.message;
            setError(
              errorMessage.substring(
                errorMessage.indexOf("/") + 1,
                errorMessage.length - 2
              )
            );
          });

        if (data.get("userType") === "Customer") {
          const cust_sharedProjects = doc(
            store,
            userStorage.sharedProjectsCustomer,
            data.get("email")
          );
          const sharedProjectsData = {};
          setDoc(cust_sharedProjects, sharedProjectsData)
            .then((docRef) => {})
            .catch((error) => {
              console.log(error);
              const errorMessage = error.message;
              setError(
                errorMessage.substring(
                  errorMessage.indexOf("/") + 1,
                  errorMessage.length - 2
                )
              );
            });
          navigate("/customer/mobile");
        } else if (data.get("userType") === "Designer") {
          const des_sharedProjects = doc(
            store,
            userStorage.sharedProjectsDesigner,
            data.get("email")
          );
          const sharedProjectsData = {};
          setDoc(des_sharedProjects, sharedProjectsData)
            .then((docRef) => {})
            .catch((error) => {
              const errorMessage = error.message;
              setError(
                errorMessage.substring(
                  errorMessage.indexOf("/") + 1,
                  errorMessage.length - 2
                )
              );
            });
          //create file named projectList.json in storage with empty json object
          const newDir = URL.baseURL + "/" + data.get("email") + "/projectList.json";
          console.log(newDir);

          const ghostFile = ref(storage, newDir);
          console.log(ghostFile);

          var jsonData = {};
          uploadString(ghostFile, JSON.stringify(jsonData), "raw", {
            contentType: "application/json",
          })
            .then(() => {
              // load();
              // setMessage("Project Created!");
              // handleSnackClick();
            })
            .catch((error) => {
              const errorMessage = error.message;
              setError(
                errorMessage.substring(
                  errorMessage.indexOf("/") + 1,
                  errorMessage.length - 2
                )
              );
            });
          navigate("/designer");
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(
          errorMessage.substring(
            errorMessage.indexOf("/") + 1,
            errorMessage.length - 2
          )
        );
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Avatar
            alt="logo of Interia"
            src={InteriaLogo}
            sx={{
              width: 150,
              height: 150,
            }}
          />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={handleThemeToggle}
            color="inherit"
          >
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel id="userTypeLabel">Select the type of user</InputLabel>
                <Select
                  labelId="userTypeLabel"
                  required
                  fullWidth
                  name="userType"
                  value={userType}
                  onChange={(event) => {
                    setUserType(event.target.value);
                  }}
                >
                  <MenuItem value={"Customer"}>Customer</MenuItem>
                  <MenuItem value={"Designer"}>Designer</MenuItem>
                </Select>
              </Grid>
              {userType === "Designer" && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="organization"
                    label="Organization"
                    name="organization"
                    autoComplete="organization"
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                {error && (
                  <Typography variant="body1" color="error">
                    {error}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
