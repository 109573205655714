import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import InteriaLogo from "../../icons/interiaLogo.svg";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { auth, store } from "../authentication/Firebase";
import { doc, getDoc } from "firebase/firestore";
import { userStorage } from "../constants";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="/"
        style={{ fontFamily: "Audiowide" }}
      >
        Interia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function SignIn() {
  const navigate = useNavigate();
  const [error, setError] = React.useState(""); // State to hold authentication error
  const [darkMode, setDarkMode] = React.useState(() => {
    // Retrieve the mode from localStorage and return the appropriate boolean
    return localStorage.getItem('theme') === 'dark';
  });
  
  React.useEffect(() => {
    // Update the localStorage whenever darkMode changes
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);
  
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  const handleThemeToggle = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithEmailAndPassword(auth, data.get("email"), data.get("password"))
        .then(async (userCredential) => {
          const uid = userCredential.user.uid;
          const docRef = doc(store, userStorage.users, uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            var user = docSnap.data();
            localStorage.setItem("Organization", user.organization);
            if (user.userType === "Customer") navigate("/customer/mobile");
            else if (user.userType === "Designer") {
              navigate("/designer");
            }
          }
        })
        .catch((error) => {
          const errorMessage = error.message;
          setError(
            errorMessage.substring(
              errorMessage.indexOf("/") + 1,
              errorMessage.length - 2
            )
          );
        });
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link href="/">
            <Avatar
              alt="logo of Interia"
              src={InteriaLogo}
              sx={{
                width: 150,
                height: 150,
              }}
            />
          </Link>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={handleThemeToggle}
            color="inherit"
          >
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {error && (
              <Typography
                variant="body2"
                color="error"
                align="center"
                style={{ fontSize: "1.2rem" }}
              >
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;
