import React from "react";
import Stack from "@mui/material/Stack";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Tooltip from "@mui/material/Tooltip";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ListItemText from "@mui/material/ListItemText";

const TreeItemOptions = (props) => {
  const {
    nodeId,
    labelText,
    labelIcon: LabelIcon,
    treeItemOptions,
    onDrop,
    ...other
  } = props;

  const {
    addFunction,
    deleteFunction,
    shareFunction,
    addTitle,
    deleteTitle,
    editable,
    renameFunction,
  } = treeItemOptions;

  // Handle onDrop event
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Check if a drop function is provided
    if (onDrop) {
      onDrop(e);
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    const parent = e.target.closest('li');
    const editableText = parent.querySelector(".editableText");

    if (editableText) {
      editableText.contentEditable = true;
      editableText.focus();
      
      const handleEnter = (e) => {
        if (e.key === "Enter") {
          renameFunction(editableText.textContent);
          editableText.contentEditable = false;
          editableText.removeEventListener("keypress", handleEnter);
          editableText.blur();
        }
      };

      editableText.addEventListener("keypress", handleEnter);
      editableText.addEventListener("blur", () => {
        editableText.contentEditable = false;
      });
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()} // Allow drop
      style={{ padding: 0 }}
      {...other}
>
      {LabelIcon && (
        <IconButton onClick={(e) => e.stopPropagation()} aria-label={`Icon for ${labelText}`}>
          <LabelIcon fontSize="small" />
        </IconButton>
      )}
      <ListItemText
        style={{ userSelect: "none" }}
        className="editableText"
        primary={labelText}
      />
      {other.loadingIcon && other.loadingIcon}
      <Stack direction="row" onDoubleClick={(e) => e.stopPropagation()}>
        {editable && (
          <Tooltip title={`Edit ${deleteTitle} name`} arrow>
            <IconButton
              onClick={handleEditClick}
              aria-label={`Edit ${deleteTitle}`}
            >
              <ModeEditOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {addFunction && (
          <Tooltip title={`Add ${addTitle}`} arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                addFunction();
              }}
              aria-label={`Add ${addTitle}`}
            >
              <ControlPointIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {other.closeButton && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              other.handleDrawerClose();
            }}
            aria-label={`Close`}
          >
            <other.closeButton fontSize="small" />
          </IconButton>
        )}
        {deleteFunction && (
          <Tooltip title={`Delete ${deleteTitle}`} arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteFunction();
              }}
              aria-label={`Delete ${deleteTitle}`}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {shareFunction && (
          <Tooltip title={`Share ${deleteTitle}`} arrow>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                shareFunction();
              }}
              style={{ transform: "scaleX(-1)" }}
              aria-label={`Share ${deleteTitle}`}
            >
              <ReplyOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default TreeItemOptions;
