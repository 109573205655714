import React, { useState } from "react";
import "aframe";

const MenuItem = ({
  text,
  image,
  position,
  uniqueID,
  width,
  height,
  textWidth,
  setSelectedID,
  selectable,
  className,
  primaryTextColor,
  secondaryTextColor,
  primaryPlaneColor,
  secondaryPlaneColor,
}) => {

  if (!window.AFRAME.components["outer_plane"]) {
    window.AFRAME.registerComponent("outer_plane", {
      schema: { selectable: { type: "boolean", default: true } },
      init: function () {
        var selectable = this.data.selectable; // Reference to the selectable property
        this.el.addEventListener("mouseenter", function (evt) {
          if (!selectable) return; // Prevent event if not selectable
          this.setAttribute("color", secondaryPlaneColor);
          // Increase scale by 1.1
          this.object3D.scale.set(1, 1, 1.2);
          // Find a-text element inside it
          var text = this.querySelector("a-text");
          text.setAttribute("color", secondaryTextColor);
        });
        this.el.addEventListener("mouseleave", function (evt) {
          if (!selectable) return; // Prevent event if not selectable
          this.setAttribute("color", primaryPlaneColor);
          // Reset scale
          this.object3D.scale.set(1, 1, 1);
          // Find a-text element inside it
          var text = this.querySelector("a-text");
          text.setAttribute("color", primaryTextColor);
        });
        this.el.addEventListener("click", function (evt) {
          if (!selectable) return; // Prevent event if not selectable
          // Get uniqueID of the clicked element
          var uniqueID = this.getAttribute("uniqueID");
          console.log("setting",uniqueID); 
          setSelectedID(uniqueID);
        });
        this.el.addEventListener("raycaster-intersected", function (evt) {
          if (!selectable) return; // Prevent event if not selectable
          console.log("intersected");
          this.setAttribute("color", secondaryPlaneColor);
          this.object3D.scale.set(1, 1, 1.2);
          var text = this.querySelector("a-text");
          text.setAttribute("color", secondaryTextColor);
        });
        this.el.addEventListener(
          "raycaster-intersected-cleared",
          function (evt) {
            if (!selectable) return; // Prevent event if not selectable
            console.log("intersected cleared");
            this.setAttribute("color", primaryPlaneColor);
            this.object3D.scale.set(1, 1, 1);
            var text = this.querySelector("a-text");
            text.setAttribute("color", primaryTextColor);
          }
        );
      },
    });
  }

  if (!window.AFRAME.components["right-controllor"]) {
    window.AFRAME.registerComponent("right-controllor", {
      init: function () {
        this.el.addEventListener("triggertouchstart", (e) => {
          const intersectedEls = e.target.components.raycaster.intersectedEls;
          if (intersectedEls.length > 0) {
            const intersectedEl = intersectedEls[0];
            
              console.log("clicked");
              var uniqueID = intersectedEl.getAttribute("uniqueID");
              setSelectedID(uniqueID);
              console.log(uniqueID);
            
          }
        });
      },
    });
  }
  return (
    <a-plane
      position={position}
      uniqueID={uniqueID}
      width={width}
      height={height}
      color={primaryPlaneColor}
      outer_plane={`selectable: ${selectable}`}
      class={className}
    >
      <a-plane
        src={image}
        width={width - 1}
        height={height - 1}
        position="0 0 0"
        inner_plane
      >
        <a-image
          src={image}
          width={width - 0.2}
          opacity="0.7"
          height={height - 0.2}
          position="0 0 0"
        />
        <a-text
          width={textWidth}
          align="center"
          position="0 0 0.1"
          value={text}
          color={primaryTextColor || "black"}
        />
      </a-plane>
    </a-plane>
  );
};
export default MenuItem;
