import React, { useEffect, useState, useContext } from "react";
import ReactDom from "react-dom";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import {
  List,
  ListItem,
  ClickAwayListener,
} from "@mui/material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddIcon from "@mui/icons-material/Add";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ListItemIcon from "@mui/material/ListItemIcon";
import Popper from "@mui/material/Popper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FilterHdrTwoToneIcon from "@mui/icons-material/FilterHdrTwoTone";
import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { AppContext, } from "../../AppContext.js";
import {
  ref,
  listAll,
  uploadBytes,
  uploadString,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import { auth, store } from "../authentication/Firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { userStorage, URL } from "../constants";
import { storage } from "../authentication/Firebase.js";
import TreeItemOptions from "./TreeItem.js";
import { collection, getDocs } from "firebase/firestore";



const imageExtensionList = [
  "mp4",
  "mov",
  "mkv",
  "ase",
  "art",
  "bmp",
  "blp",
  "cd5",
  "cit",
  "cpt",
  "cr2",
  "cut",
  "dds",
  "dib",
  "djvu",
  "egt",
  "exif",
  "gif",
  "gpl",
  "grf",
  "icns",
  "ico",
  "iff",
  "jng",
  "jpeg",
  "jpg",
  "jfif",
  "jp2",
  "jps",
  "lbm",
  "max",
  "miff",
  "mng",
  "msp",
  "nef",
  "nitf",
  "ota",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pcx",
  "pdn",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "pct",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "pdd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "raw",
  "rle",
  "sct",
  "sgi",
  "rgb",
  "int",
  "bw",
  "tga",
  "tiff",
  "tif",
  "vtf",
  "xbm",
  "xcf",
  "xpm",
  "3dv",
  "amf",
  "ai",
  "awg",
  "cgm",
  "cdr",
  "cmx",
  "dxf",
  "e2d",
  "egt",
  "eps",
  "fs",
  "gbr",
  "odg",
  "svg",
  "stl",
  "vrml",
  "x3d",
  "sxd",
  "v2d",
  "vnd",
  "wmf",
  "emf",
  "art",
  "xar",
  "png",
  "webp",
  "jxr",
  "hdp",
  "wdp",
  "cur",
  "ecw",
  "iff",
  "lbm",
  "liff",
  "nrrd",
  "pam",
  "pcx",
  "pgf",
  "sgi",
  "rgb",
  "rgba",
  "bw",
  "int",
  "inta",
  "sid",
  "ras",
  "sun",
  "tga",
  "heic",
  "heif",
];

const drawerWidth = 400;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function ProjectBrowser() {
  const {
    metadataRef,
    setMetaDataRef,
    inHome,
    setInHome,
    open,
    setOpen,
    setAnchorE1,
    snackOpen,
    handleSnackClick,
    handleSnackClose,
    action,
    message,
    setMessage,
    setfs,
    fs,
    storageRef,
    setStorageRef,
    useConfirmDialog,
  } = useContext(AppContext);
  const theme = useTheme();
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [sharedProjects, setSharedProjects] = useState([]);
  const [desAnchorEl, setDesAnchorEl] = useState(null);
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [desOpen, setDesOpen] = useState(false);
  const [selectedMail, setSelectedMail] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [customerAnchorEl, setCustomerAnchorEl] = useState(null);
  const [checked, setChecked] = React.useState([-1]);
  const [projLoading, setProjLoading] = useState(false);
  const [selectedProjectName, setSelectedProjectName] = useState(null);

  useEffect(() => {
    //setting storage reference to current user's email
    var tempRef = ref(storage, "/" + auth.currentUser.email);
    setStorageRef(tempRef);
    load();
  }, [open]);

  useEffect(() => {
    console.log("Shared Projects:", sharedProjects);
  }, [sharedProjects]);
    

  useEffect(() => {
    if (inHome === true) {
      handleDesClose();
      //clear selected project,input fields,anchors,emaillist and room count
      setSelectedProjectID(null);
      setAnchorE1(null);
      setEmailList([]);
      setEmail("");
      setDesAnchorEl(null);
      setSelectedMail(null);
    }
  }, [inHome]);


  //checkbox toggling function for multi-select in editing projects for customers
  const handleToggleCheckBox = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  //function to handle share project button click
  const handleDesClick = (projID) => {
    //place theanchor where the selected project is
    var element = document.querySelector(`[uniqueId="${projID}"]`);
    setSelectedProjectID(projID);
    setSelectedProjectName(fs[projID].name);
    setDesAnchorEl(element);
    setDesOpen((prev) => !prev);
  };
  //function to handle share project button close
  const handleDesClose = () => {
    setSelectedProjectID(null);
    setEmailList([]);
    setEmail("");
    setDesOpen(false);
    setDesAnchorEl(null);
  };
  //function to handle add email button click
  const handleAddEmail = () => {
    if (email && !emailList.includes(email)) {
      setEmailList([...emailList, email]);
      setEmail("");
    }
  };
  //function to handle share project to add mails in DB
  const handleDesSubmit = async () => {
    try {
      for (const email of emailList) {
        setMessage("Sharing Project with " + email);
        handleSnackClick();

        const designerDb = doc(
          store,
          userStorage.sharedProjectsDesigner,
          auth.currentUser.email
        );
        const customerDb = doc(
          store,
          userStorage.sharedProjectsCustomer,
          email
        );

        var userExists = false;
        if ((await getDoc(customerDb)).exists()) {
          userExists = true;
        }

        if (userExists) {
          // Fetch existing data
          let designerData = (await getDoc(designerDb)).data() || {};
          let customerData = (await getDoc(customerDb)).data() || {};

          // Create or update JSON object with projID:projName
          const projectPair = { [selectedProjectID]: selectedProjectName };

          // Update designer data
          designerData = {
            ...designerData,
            [email]: {
              ...(designerData[email] || {}),
              ...projectPair,
            },
          };

          // Update customer data
          customerData = {
            ...customerData,
            [auth.currentUser.email]: {
              ...(customerData[auth.currentUser.email] || {}),
              ...projectPair,
            },
          };

          // Update designer's shared projects
          await setDoc(designerDb, designerData, { merge: true });

          // Update customer's shared projects
          await setDoc(customerDb, customerData, { merge: true });
        } else {
          setMessage(email + " in the list does not exist!");
          handleSnackClick();
          return;
        }
      }

      await getSharedProjects();
      setMessage("Project Shared!");
      handleSnackClick();
      handleDesClose();
    } catch (error) {
      setMessage("Error sharing project!");
      handleSnackClick();
    }
  };
  //function to handle delete email button click
  const handleDeleteEmail = (emailToDelete) => {
    setEmailList(emailList.filter((email) => email !== emailToDelete));
  };
  // function to handle edit project to edit shared mails in DB
  const handleCusSubmit = async () => {
    try {
      const customerDb = doc(
        store,
        userStorage.sharedProjectsCustomer,
        selectedMail
      );

      // Get current customer data and prepare new project data
      let customerData = (await getDoc(customerDb)).data() || {};
      //get the selected projects from the checked array and make json like projID and project anme
      const selectedProjects = checked.reduce((acc, index) => {
        acc[projectList[index]] = fs[projectList[index]].name;
        return acc;
      }, {});
      // Update customer data with selected projects
      customerData[auth.currentUser.email] = selectedProjects;

      // Update designer data with selected projects
      const designerDb = doc(
        store,
        userStorage.sharedProjectsDesigner,
        auth.currentUser.email
      );
      let designerData = (await getDoc(designerDb)).data() || {};
      designerData[selectedMail] = selectedProjects;

      // Commit changes to the database
      await setDoc(designerDb, designerData, { merge: true });
      await setDoc(customerDb, customerData, { merge: true });

      await getSharedProjects();
      setCustomerAnchorEl(null);
      setCustomerOpen(false);
      setSelectedMail("");
      setMessage("Share access updated!");
      handleSnackClick();
    } catch (error) {
      console.error("Error updating share access:", error);
      setMessage("Error updating share access!");
      handleSnackClick();
    }
  };
  //function to handle customer popper close
  const handleCusClick = (event, email) => {
    event.preventDefault();
    setSelectedMail(email);

    // Reset checked state
    setChecked([]);

    var updatedChecked = [];
    // Get the list of projects shared with the customer
    var projects = sharedProjects[email] || {};
    //now match the projects with the project list and push the index of the project in the checked array
    projectList.forEach((projID, index) => {
      if (projects[projID]) {
        updatedChecked.push(index);
      }
    });

    setChecked(updatedChecked);
    setCustomerAnchorEl(event.target);
    setCustomerOpen(true);
  };
  //function to upload file
  async function uploadFile(storage, reference, file) {
    setIsFileUploading(true);
    var ele = document.getElementById("circularprogress");
    const node = (
      <Stack
        id="circular"
        direction="row"
        alignItems="center"
        alignContent="left"
        spacing={2}
      >
        <CircularProgress size="15px" />
        <p>Uploading {file.name}</p>
      </Stack>
    );
    ReactDom.render(node, ele);
    const storageRef = ref(storage, reference);
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        ReactDom.unmountComponentAtNode(ele);
        document.getElementById("circularprogress").remove();
        setIsFileUploading(false);
        setMessage("File Uploaded!");
        handleSnackClick();
      })
      .catch((error) => {
        ReactDom.unmountComponentAtNode(ele);
        document.getElementById("circularprogress").remove();
        setIsFileUploading(false);
        setMessage("Error uploading file!");
        handleSnackClick();
      });
  }
  //function to update projectList.json
  const uploadJSON = (data) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const storageRef = ref(
      storage,
      URL.baseURL + "/" + auth.currentUser.email + "/projectList.json"
    );
    setProjLoading(false);
    uploadBytes(storageRef, blob)
      .then((snapshot) => {
        setProjLoading(true);
        load();
        setfs(data);
        getSharedProjects();
      })
      .catch((error) => {
        setProjLoading(false);
        setMessage("Error performing the requested task!");
        handleSnackClick();
      });
  };
  //function to get files from firebase storage
  async function getFiles(storageRef) {
    try {
      // Reference to the projectList.json file
      const projectListRef = ref(storageRef, "projectList.json");

      // Get the download URL for the file
      const downloadURL = await getDownloadURL(projectListRef);

      // Fetch the file contents
      const response = await fetch(downloadURL);

      // Parse the JSON data
      const projectListData = await response.json();

      // Return the parsed data
      return projectListData;
    } catch (error) {
      console.error("Error fetching project list:", error);
      return {}; // Return an empty object in case of error
    }
  }
  //function to handle drawer open
  const handleDrawerOpen = () => {
    setOpen(true);
    setAnchorE1(null);
  };
  //function to handle drawer close
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //function to render tree structure of project browser
  const renderTree = (nodes) => {
    if (nodes == null) return null;
    if(Object.keys(nodes).includes("error")) return null;

    const handleDrop = (e, callback) => {
      e.preventDefault();
      e.target.style.backgroundColor = "transparent";

      if (e.dataTransfer.files.length !== 1) {
        setMessage("Only one file is accepted!");
        handleSnackClick();
        return;
      }

      const file = e.dataTransfer.files[0];
      if (
        file.type.includes("image") ||
        imageExtensionList.includes(file.name.split(".").pop())
      ) {
        callback(file);
      } else {
        setMessage("Only image files are accepted!");
        handleSnackClick();
      }
    };

    const createFileInput = (callback) => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.multiple = false;
      fileInput.click();
      fileInput.addEventListener("change", (e) => {
        const file = e.target.files[0];
        callback(file);
      });
    };
    

    return Object.keys(nodes).map((projID) => {
      const projectName = nodes[projID].name;

      return (
        <TreeItem
          key={projID}
          nodeId={projID}
          uniqueId={projID}
          label={TreeItemOptions({
            nodeId: projID,
            labelText: projectName,
            labelIcon: HomeIcon,
            treeItemOptions: {
              addFunction: () => createRoom(projID),
              deleteFunction: () => deleteProject(projID),
              shareFunction: () => handleDesClick(projID),
              addTitle: "Room",
              deleteTitle: "Project",
              editable: true,
              renameFunction: (newName) => renameProject(projID, newName),
            },
            onDrop: (e) =>
              handleDrop(e, (file) => createView(projID, null, file)),
          })}
          expandIcon={<ChevronRightIcon/>}
          collapseIcon={< ExpandMoreIcon />}
        >
          {nodes[projID].rooms &&
            Object.keys(nodes[projID].rooms).map((roomID) => {
              const roomName = nodes[projID].rooms[roomID].name;

              return (
                <TreeItem
                  key={projID + roomID}
                  nodeId={projID + roomID}
                  uniqueId={projID + roomID}
                  label={TreeItemOptions({
                    nodeId: roomID,
                    labelText: roomName,
                    labelIcon: MeetingRoomIcon,
                    treeItemOptions: {
                      addFunction: () =>
                        createFileInput((file) =>
                          createView(projID, roomID, file)
                        ),
                      deleteFunction: () => deleteRoom(projID, roomID),
                      addTitle: "View",
                      deleteTitle: "Room",
                      editable: true,
                      renameFunction: (newName) =>
                        renameRoom(projID, roomID, newName),
                    },
                    onDrop: (e) =>
                      handleDrop(e, (file) => createView(projID, roomID, file)),
                  })}
                  expandIcon={< ChevronRightIcon/>}
                  collapseIcon={<ExpandMoreIcon />}
                >
                  {nodes[projID].rooms[roomID].views &&
                    Object.keys(nodes[projID].rooms[roomID].views).map(
                      (viewID) => {
                        const viewName =
                          nodes[projID].rooms[roomID].views[viewID];

                        return (
                          <TreeItem
                            key={projID + roomID + viewID}
                            nodeId={projID + roomID + viewID}
                            uniqueId={projID + roomID + viewID}
                            label={TreeItemOptions({
                              nodeId: viewID,
                              labelText: viewName,
                              labelIcon: FilterHdrTwoToneIcon,
                              treeItemOptions: {
                                deleteFunction: () =>
                                  deleteView(projID, roomID, viewID),
                                addTitle: "Image",
                                deleteTitle: "View",
                                editable: true,
                                renameFunction: (newName) =>
                                  renameView(projID, roomID, viewID, newName),
                              },
                              onDrop: (e) =>
                                handleDrop(e, (file) =>
                                  createView(projID, roomID, file)
                                ),
                            })}
                            icon={<InsertDriveFileIcon />}
                            draggable
                            onDragStart={(e) => {
                              e.dataTransfer.setData(
                                "viewID",
                                projID + "/" + roomID + "/" + viewID
                              );
                            }}
                          />
                        );
                      }
                    )}
                </TreeItem>
              );
            })}
        </TreeItem>
      );
    });
  };
  //function to create new project
  const createProject = async () => {
    // Create a new project ID by finding the next available number
    const projectIDs = Object.keys(fs);
    let newProjectID = 1;
    while (projectIDs.includes(`P${newProjectID}`)) {
      newProjectID++;
    }
    // Create a new project object
    const newProject = {
      name: `Project ${newProjectID}`,
      rooms: {},
    };
    // Add the new project to the file system
    var newFS = fs;
    newFS[`P${newProjectID}`] = newProject;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("Project Created!");
    handleSnackClick();
  };
  //function to rename project
  const renameProject = async (projectID, newName) => {
    // Update the project name in the file system
    //check if project name already exists
    if (Object.keys(fs).includes(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(`[uniqueId="${projectID}"]`);
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].name;
      setMessage("Project name already exists!");
      handleSnackClick();
      return;
    }
    //project name should be only alphabetical with spaces adn no special characters and numbers and between 3-20 characters
    if (!/^[a-zA-Z ]{3,20}$/.test(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(`[uniqueId="${projectID}"]`);
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].name;
      setMessage(
        "Project name should be between 3-20 characters and only alphabets!"
      );
      handleSnackClick();
      return;
    }
    var newFS = fs;
    newFS[projectID].name = newName;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("Project Renamed!");
    handleSnackClick();
  };
  //function to delete project
  function splitMetadataRefForProjects(input) {
    const lastSlashIndex = input.lastIndexOf('/');
    const thirdLastSlashIndex = input.lastIndexOf('/', lastSlashIndex - 1);
    const fourthLastSlashIndex = input.lastIndexOf('/', thirdLastSlashIndex - 1);

    if (fourthLastSlashIndex === -1 || thirdLastSlashIndex === -1) return '';

    return input.substring(fourthLastSlashIndex + 1, thirdLastSlashIndex);
  }
  const deleteProject = async (projectID) => {

    if (splitMetadataRefForProjects(metadataRef) === projectID) {
      setMessage("Cannot delete a project that is currently open! Please go to Home and try again.");
      handleSnackClick();
      return;
    }

    // Delete the project from the file system
    var newFS = fs;

    // Delete all images and metadata files from Firebase storage
    if (Object.keys(fs).includes(projectID) && fs[projectID].rooms.length > 0) {
      // Delete each room
      Object.keys(fs[projectID].rooms).forEach((roomID) => {
        deleteRoom(projectID, roomID);
      });
    }
    delete newFS[projectID];

    // Check if project is shared with any customer and delete it from shared projects
    await Promise.all(
      Object.keys(sharedProjects).map(async (email) => {
        if (sharedProjects[email][projectID]) {
          const customerDb = doc(store, userStorage.sharedProjectsCustomer, email);
          const designerDb = doc(store, userStorage.sharedProjectsDesigner, auth.currentUser.email);

          var customerData = (await getDoc(customerDb)).data();
          var designerData = (await getDoc(designerDb)).data();

          delete customerData[auth.currentUser.email][projectID];
          delete designerData[email][projectID];

          await setDoc(customerDb, customerData, { merge: false });
          await setDoc(designerDb, designerData, { merge: false });
        }
      })
    );

    // Upload the updated file system to Firebase Storage after all setDoc operations
    uploadJSON(newFS);

    setMessage("Project Deleted!");
    setDesAnchorEl(null);
    setDesOpen(false);
    setInHome(true);
    handleSnackClick();
};

  //function to create new room
  const createRoom = async (projectID) => {
    // Create a new room ID by finding the next available number
    const roomIDs = Object.keys(fs[projectID].rooms);
    let newRoomID = 1;
    while (roomIDs.includes(`R${newRoomID}`)) {
      newRoomID++;
    }
    // Create a new room object
    const newRoom = {
      name: `Room ${newRoomID}`,
      views: {},
    };
    // Add the new room to the project
    var newFS = fs;
    newFS[projectID].rooms[`R${newRoomID}`] = newRoom;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("Room Created!");
    handleSnackClick();
  };
  //function to rename room
  const renameRoom = async (projectID, roomID, newName) => {
    // Update the room name in the file system
    //check if room name already exists
    if (Object.keys(fs[projectID].rooms).includes(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + roomID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].rooms[roomID].name;
      setMessage("Room name already exists!");
      handleSnackClick();
      return;
    }
    //room name should be only alphabetical with spaces adn no special characters and numbers and between 3-20 characters
    if (!/^[a-zA-Z ]{3,20}$/.test(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + roomID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].rooms[roomID].name;
      setMessage(
        "Room name should be between 3-20 characters and only alphabets!"
      );
      handleSnackClick();
      return;
    }
    var newFS = fs;
    newFS[projectID].rooms[roomID].name = newName;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("Room Renamed!");
    handleSnackClick();
  };
  //function to delete room
  const deleteRoom = async (projectID, roomID) => {
    // Delete the room from the project
    var newFS = fs;
    delete newFS[projectID].rooms[roomID];
    //delete all images and metadata files from firebase storage
    const roomRef = ref(
      storage,
      `${auth.currentUser.email}/${projectID}/${roomID}`
    );
    listAll(roomRef).then((res) => {
      res.items.forEach((itemRef) => {
        deleteObject(itemRef)
          .then(() => {
            console.log("Image deleted!");
          })
          .catch((error) => {
            console.error("Error deleting image:", error);
            setMessage("Error deleting room!");
            handleSnackClick();
            return;
          });
      });
    });
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("Room Deleted!");
    handleSnackClick();
  };
  //function to create new view
  const createView = async (projectID, roomID, file) => {
    // Create a new view ID by finding the next available number
    const viewIDs = Object.keys(fs[projectID].rooms[roomID].views);
    let newViewID = 1;
    while (viewIDs.includes(`V${newViewID}`)) {
      newViewID++;
    }
    // Create a new view object
    const newView = `view${newViewID}`;
    //create element to show progress
    var ele = document.createElement("div");
    var element = document.querySelector(`[uniqueId="${projectID + roomID}"]`);
    element.appendChild(ele);
    ele.id = "circularprogress";
    //upload file
    uploadFile(
      storage,
      `${auth.currentUser.email}/${projectID}/${roomID}/V${newViewID}.png`,
      file
    );
    //create metadata for the uploaded file
    const metadata = {};
    //upload metadata to a new file in firebase storage
    const metadataRef = ref(
      storage,
      `${auth.currentUser.email}/${projectID}/${roomID}/V${newViewID}-metadata.json`
    );
    uploadString(metadataRef, JSON.stringify(metadata), "raw", {
      contentType: "application/json",
    });
    // Add the new view to the room
    var newFS = fs;
    newFS[projectID].rooms[roomID].views[`V${newViewID}`] = newView;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("View Created!");
    handleSnackClick();
  };
  //function to rename view
  const renameView = async (projectID, roomID, viewID, newName) => {
    // Update the view name in the file system
    //check if view name already exists
    if (Object.keys(fs[projectID].rooms[roomID].views).includes(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + roomID + viewID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].rooms[roomID].views[viewID];
      setMessage("View name already exists!");
      handleSnackClick();
      return;
    }
    //view name should be only alphabetical with spaces adn no special characters and numbers and between 3-20 characters
    if (!/^[a-zA-Z ]{3,20}$/.test(newName)) {
      //get element with unique id equal to projectID
      var element = document.querySelector(
        `[uniqueId="${projectID + roomID + viewID}"]`
      );
      //get element with editableText class
      var editableText = element.querySelector(".editableText");
      //set editable text to old name
      editableText.textContent = fs[projectID].rooms[roomID].views[viewID];
      setMessage(
        "View name should be between 3-20 characters and only alphabets!"
      );
      handleSnackClick();
      return;
    }
    var newFS = fs;
    newFS[projectID].rooms[roomID].views[viewID] = newName;
    // Upload the updated file system to Firebase Storage
    uploadJSON(newFS);
    setMessage("View Renamed!");
    handleSnackClick();
  };
  //function to delete view
  function splitMetadataRefForView(input) {
    const lastSlashIndex = input.lastIndexOf('/');
    if (lastSlashIndex === -1) return '';
    return input.substring(lastSlashIndex + 1).split('-')[0];
  }
  const deleteView = async (projectID, roomID, viewID) => {


    // Delete the view from the room
    var newFS = fs;
    //delete image file from firebase storage and metadata file
    const imageRef = ref(
      storage,
      `${auth.currentUser.email}/${projectID}/${roomID}/${viewID}.png`
    );
    const metadataRef = ref(
      storage,
      `${auth.currentUser.email}/${projectID}/${roomID}/${viewID}-metadata.json`
    );
    if (splitMetadataRefForView(metadataRef.toString()) === viewID) {
      setMessage("Cannot delete a view that is currently open! Please go to Home and try again.");
      handleSnackClick();
      return;
    }
    deleteObject(imageRef)
      .then(() => {
        console.log("Image deleted!");
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
        setMessage("Error deleting view!");
        handleSnackClick();
        return;
      });

    deleteObject(metadataRef)
      .then(() => {
        console.log("Metadata deleted!");
      })
      .catch((error) => {
        console.error("Error deleting metadata:", error);
        setMessage("Error deleting view!");
        handleSnackClick();
        //go out of function
        return;
      });
    // Upload the updated file system to Firebase Storage
    delete newFS[projectID].rooms[roomID].views[viewID];
    uploadJSON(newFS);
    setMessage("View Deleted!");
  };
  //function to get shared projects from DB
  const getSharedProjects = async () => {
    const sharedProjects = doc(
      store,
      userStorage.sharedProjectsDesigner,
      auth.currentUser.email
    );
    const sharedProjectsData = (await getDoc(sharedProjects)).data();
    setSharedProjects(sharedProjectsData);
  };
  //function to load projects from firebase storage
  const load = async () => {
    if (storageRef == null) return;
    getFiles(storageRef)
      .then((result) => {
        setProjLoading(true);
        setfs(result);
        setProjectList(Object.keys(result));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    await getSharedProjects();
    setProjLoading(false);
  };
  useEffect(() => {
    load();
  }, []);

  //audio playing code

  const [audioLoaded, setAudioLoaded] = useState(false);
  const [audioURL, setAudioURL] = useState(null);

  useEffect(() => {
    if (audioLoaded === true) {
      const audioElement = document.getElementById("audioPlayer");
      if (audioElement) {
        audioElement.play();
      }
    }
  }, [audioLoaded]);

  function playRef() {
    const storageRef = ref(storage, "audio/audio.mp3");

    getDownloadURL(storageRef)
      .then((url) => {
        setAudioURL(url);
        setAudioLoaded(true);
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }
  const { confirm, dialog } = useConfirmDialog();
  //test starts

  const handleGetUserNameByEmail = (emailToFind) => {
    return new Promise((resolve, reject) => {
      const usersCollectionRef = collection(store, "multivrseInteriaUsers");

      getDocs(usersCollectionRef)
        .then((querySnapshot) => {
          let userName = null;
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            if (userData.email === emailToFind) {
              userName = `${userData.firstname} ${userData.lastname}`;
            }
          });
          resolve(userName);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
          reject(error);
        });
    });
  };
  const fetchUserName = (email) => {
    let userName = "Loading...";

    handleGetUserNameByEmail(email)
      .then((name) => {
        userName = name || "Name not found";
        document.getElementById(`userName-${email}`).textContent = userName
          .toLowerCase()
          .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());
      })
      .catch((error) => {
        console.error("Error fetching user name:", error);
        userName = "Error fetching name";
        document.getElementById(`userName-${email}`).textContent = userName
          .toLowerCase()
          .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());
      });

    return userName
      .toLowerCase()
      .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());
  };

  ///test ends
  return (
    <div className="manager" style={{ zIndex: 2 }} onLoadStart={load}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        style={{
          position: "fixed",
          top: "150px", // Move Snackbar downward
          left: "50%",
          transform: "translateX(-50%)",
        }}
        ContentProps={{
          style: {
            fontSize: "4rem", // Increase font size
            padding: "24px 32px", // Increase padding
            minWidth: "350px", // Increase width
            textAlign: "center", // Center the text if needed
          },
        }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="info"
          variant="filled"
          sx={{
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
            color:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          }}
        >
          {message.toString()}
        </Alert>
      </Snackbar>
      {/* button for project briwser */}
      <div className="nav_button">
        <Button
          variant="text"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={(theme) => ({
            ...(open && { display: "none" }),
            zIndex: 1102,
            height: "60vh",
            padding: "0px",
            marginLeft: "10px",
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
            color: theme.palette.mode === "dark" ? "white" : "black",
          })}
        >
          <ChevronRightIcon />
        </Button>
      </div>
      <Drawer
        sx={(theme) => ({
          ...(open && {
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            marginLeft: "10px",
            border: "1px solid",
            borderColor: "divider",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            borderRadius: "10px",
          }),
          zIndex: 1102,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            ...(open && {
              position: "relative",
              width: drawerWidth,
              boxSizing: "border-box",
              height: "60vh",
              alignSelf: "center",
              bgcolor: "rgb(0,0,0,0)",
              borderRadius: "10px",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              // color:
              //   theme.palette.mode === "dark"
              //     ? "#000"
              //     : theme.palette.primary.main,
            }),
            height: "60vh",
            position: "relative",
          },
        })}
        variant="persistent"
        ModalProps={{
          keepMounted: false,
        }}
        anchor="left"
        open={open}
      >
        <Divider />
        {/* share project to multiple customer popper */}
        <Popper
          style={{ zIndex: "1201" }}
          open={desOpen}
          anchorEl={desAnchorEl}
          placement="right-start"
        >
          <ClickAwayListener onClickAway={() => setDesAnchorEl(null)}>
            <Box
              sx={{
                p: 1,
                borderRadius: 2,

                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
              }}
              // component="form"
              autoComplete="on"
              alignItems="center"
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <CloseIcon
                fontSize="small"
                onClick={() => {
                  setDesAnchorEl(null);
                  setEmailList([]);
                  setEmail("");
                  setDesOpen(false);
                }}
              ></CloseIcon>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <h4 style={{ margin: "auto" }}>
                  Share {selectedProjectID && Object.keys(fs).includes(selectedProjectID) ? fs[selectedProjectID]["name"] : false}{" "}
                  with :
                </h4>
                <TextField
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  label="Enter customer mail"
                  size="small"
                  variant="standard"
                />
                <List sx={{ width: "100%" }}>
                  {emailList.map((email, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          variant="standard"
                          onClick={() => handleDeleteEmail(email)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText id={index} primary={email} />
                    </ListItem>
                  ))}
                </List>

                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleAddEmail();
                    }}
                  >
                    <AddIcon fontSize="small" style={{ paddingRight: "5px" }} />
                    Add email
                  </Button>
                  <Button
                    disabled={emailList.length === 0}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleDesSubmit();
                    }}
                  >
                    <DoneIcon
                      fontSize="small"
                      style={{ paddingRight: "5px" }}
                    />
                    Ok
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>
        {/* edit project popper to manage shared projects */}
        <Popper
          style={{ zIndex: 1201 }}
          open={customerOpen}
          anchorEl={customerAnchorEl}
          placement="right-start"
        >
          <ClickAwayListener onClickAway={() => setCustomerAnchorEl(null)}>
            <Box
              sx={{
                p: 1,
                borderRadius: 2,

                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
              }}
              // component="form"
              autoComplete="on"
              alignItems="center"
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <CloseIcon
                fontSize="small"
                onClick={() => {
                  setCustomerAnchorEl(null);
                  setSelectedMail("");
                  setCustomerOpen(false);
                }}
              ></CloseIcon>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <h4 style={{ margin: "auto" }}>
                  Manage project access to {selectedMail} :
                </h4>
                <List sx={{ width: "100%" }}>
                  {projectList.map((projectName, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggleCheckBox(index)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(index) !== -1}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={projectName}
                          primary={Object.keys(fs).includes(projectName) ? fs[projectName]["name"] : ""}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleCusSubmit();
                    }}
                  >
                    <DoneIcon
                      fontSize="small"
                      style={{ paddingRight: "5px" }}
                    />
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>
        {/* designer space tree view */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh", // Set the container height to fill the viewport
          }}
        >
          <div style={{ flex: 1, overflow: "auto" }}>
            <TreeView
              aria-label="multi-select"
              style={{ textAlign: "left", height: "100%", overflow: "auto" }}
            >
              {/* Designer Space */}
              <TreeItem
                nodeId={"Designer Space"}
                label={TreeItemOptions({
                  nodeId: "Designer Space",
                  labelText: (
                    <span style={{ fontFamily: "Audiowide, sans-serif" }}>
                      Designer Space
                    </span>
                  ),
                  treeItemOptions: {
                    addTitle: "Project",
                    addFunction: createProject,
                  },
                  loadingIcon: !projLoading ? <CircularProgress size={20} /> : "",
                  closeButton: ChevronLeftIcon,
                  handleDrawerClose: handleDrawerClose,
                })}
                data-reference={storageRef}
                data-type="root" // Add data-type attribute for folder nodes
                icon={<ArchitectureIcon />}
                style={{ borderBottom: "1px solid #161a1d" }}
              ></TreeItem>
              {renderTree(fs)}
            </TreeView>
          </div>

          <div style={{ flex: 1, overflow: "auto" }}>
            <TreeView
              onContextMenu={(event) => event.preventDefault()}
              aria-label="multi-select"
              defaultCollapseIcon={< ExpandMoreIcon/>}
              defaultExpandIcon={< ChevronRightIcon/>}
              style={{ textAlign: "left", height: "100%", overflow: "auto" }}
            >
              <TreeItem
                key="Shared Space" // Unique key for the root TreeItem
                nodeId="Shared Space" // Unique nodeId for the root TreeItem
                label={
                  <span style={{ fontFamily: "Audiowide, sans-serif" }}>
                    Shared Space
                  </span>
                }
                collapseIcon={<GroupAddIcon color="disabled" />} // Icon for the root Tree
                icon={<GroupAddIcon />} // Always show the icon
                style={{ borderBottom: "1px solid #161a1d" }}
              ></TreeItem>
              {Object.entries(sharedProjects)
                .filter(([email, projects]) => Object.keys(projects).length > 0) // Filter out emails with no projects
                .map(([email, projects], index) => (
                  <TreeItem
                    key={index} // Unique key for each email TreeItem
                    nodeId={email} // Unique nodeId for each email TreeItem
                    label={
                      <span id={`userName-${email}`}>{fetchUserName(email)}</span>
                    } // Label for each email TreeItem
                    onContextMenu={(event) => handleCusClick(event, email)} // Custom context menu handler
                  >
                    {Object.entries(projects).map(([projID, projName], idx) => (
                      <TreeItem
                        key={idx} // Unique key for each project TreeItem
                        nodeId={`${email}-${projID}`} // Unique nodeId for each project TreeItem
                        label={projName} // Label for each project TreeItem
                      />
                    ))}
                  </TreeItem>
                ))}
            </TreeView>
          </div>
        </div>

        {dialog}
        {/* this is the audio player element */}
        {/* {audioLoaded && (
          <audio src={audioURL} id="audioPlayer">
            Play ME
          </audio>
        )} */}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </div>
  );
}
