import React, { useEffect, useRef, useState, useContext } from "react";
import "aframe";
import interia_lobby from "./interia_lobby.jpeg";
import interiaLogo from "./InteriaLogo.png";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { Button, Popper, TextField, IconButton } from "@mui/material";
import { auth, storage } from "../authentication/Firebase";
import { AppContext } from "../../AppContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { URL } from "../constants";

const imageExtensionList = [
  "mp4",
  "mov",
  "mkv",
  "ase",
  "art",
  "bmp",
  "blp",
  "cd5",
  "cit",
  "cpt",
  "cr2",
  "cut",
  "dds",
  "dib",
  "djvu",
  "egt",
  "exif",
  "gif",
  "gpl",
  "grf",
  "icns",
  "ico",
  "iff",
  "jng",
  "jpeg",
  "jpg",
  "jfif",
  "jp2",
  "jps",
  "lbm",
  "max",
  "miff",
  "mng",
  "msp",
  "nef",
  "nitf",
  "ota",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pcx",
  "pdn",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "pct",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "pdd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "raw",
  "rle",
  "sct",
  "sgi",
  "rgb",
  "int",
  "bw",
  "tga",
  "tiff",
  "tif",
  "vtf",
  "xbm",
  "xcf",
  "xpm",
  "3dv",
  "amf",
  "ai",
  "awg",
  "cgm",
  "cdr",
  "cmx",
  "dxf",
  "e2d",
  "egt",
  "eps",
  "fs",
  "gbr",
  "odg",
  "svg",
  "stl",
  "vrml",
  "x3d",
  "sxd",
  "v2d",
  "vnd",
  "wmf",
  "emf",
  "art",
  "xar",
  "png",
  "webp",
  "jxr",
  "hdp",
  "wdp",
  "cur",
  "ecw",
  "iff",
  "lbm",
  "liff",
  "nrrd",
  "pam",
  "pcx",
  "pgf",
  "sgi",
  "rgb",
  "rgba",
  "bw",
  "int",
  "inta",
  "sid",
  "ras",
  "sun",
  "tga",
  "heic",
  "heif",
];
var clickX = 0,
  clickY = 0;
const ImageViewer = () => {
  const cameraRef = useRef(null);
  const cursorRef = useRef(null);
  const sceneRef = useRef(null);
  const skyRef = useRef(null);
  const theme = useTheme();
  // const theme = useTheme();
  const [imageWidth, setImageWidth] = useState(null);
  const {
    inHome,
    setInHome,
    selectedRef,
    setOpen,
    setMessage,
    handleSnackClick,
    edit,
    setEdit,
    data,
    setData,
    currentSphereNumber,
    setCurrentSphereNumber,
    metadataOpen,
    setMetadataOpen,
    keyValues,
    setKeyValues,
    metadataAnchorEl,
    setMetadataAnchorEl,
    setMetaDataRef,
    metadataPlacement,
    setMetadataPlacement,
    setConfirmDialogText,
    useConfirmDialog,
    metadataRef,
  } = useContext(AppContext);

  const { confirm, dialog } = useConfirmDialog();

  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault();
    };

    if (cameraRef.current) {
      cameraRef.current.addEventListener("click", handleClick);
    }

    return () => {
      if (cameraRef.current) {
        // eslint-disable-next-line
        cameraRef.current.removeEventListener("click", handleClick);
      }
    };
  }, [imageWidth]);

  useEffect(() => {
    if (edit) {
      if (skyRef.current) {
        skyRef.current.addEventListener("mouseup", handleSphere);
      }
    }
    return () => {
      if (skyRef.current) {
        skyRef.current.removeEventListener("mouseup", handleSphere);
      }
    };
  }, [edit]);

  useEffect(() => {
    if (inHome === true) {
      //chnage image to interia lobby
      var newSky = document.createElement("a-sky");
      // newSky.setAttribute("src", interia_lobby);

      if (theme.palette.mode == "light") {
        newSky.setAttribute("color", "#696969");
      } else if (theme.palette.mode == "dark") {
        newSky.setAttribute("color", "#000000");
      }
      newSky.setAttribute("rotation", "0 -90 0");
      newSky.setAttribute("class", "clickable");
      if (skyRef.current) {
        skyRef.current.parentNode.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;
      }

      //remove all spheres
      Array.prototype.slice
        .call(document.getElementsByTagName("a-sphere"))
        .forEach(function (item) {
          item.remove();
        });
      //clear metadata,roomPath,rooms
      setCurrentSphereNumber("0");
    }
  }, [inHome, theme.palette.mode]);

  useEffect(() => {
    var metadata = [];
    for (const key in data[currentSphereNumber]) {
      if (key === "intersection") continue;
      metadata.push({
        key: key,
        value: data[currentSphereNumber][key],
      });
    }
    //change other sphere colors to black
    Array.prototype.slice
      .call(document.getElementsByTagName("a-sphere"))
      .forEach(function (item) {
        item.setAttribute("color", "black");
      });
    //change color of sphere
    var sphere = document.querySelector(
      'a-sphere[number="' + currentSphereNumber + '"]'
    );
    if (sphere) {
      sphere.setAttribute("color", "red");
    }
    setKeyValues(metadata);
  }, [currentSphereNumber]);

  if (!window.AFRAME.components["spheres"]) {
    window.AFRAME.registerComponent("spheres", {
      init: function () {
        this.el.addEventListener("mouseup", (event) => {
          var eventType = event.detail.mouseEvent.button;
          if (eventType === 0) {
            var canvasEl = this.el.sceneEl.canvas;
            var rect = canvasEl.getBoundingClientRect();
            clickX = event.detail.mouseEvent.clientX - rect.left;
            clickY = event.detail.mouseEvent.clientY - rect.top;
            // var camRotation = document
            //   .getElementsByTagName("a-camera")[0]
            //   .getAttribute("rotation");
            setCurrentSphereNumber(this.el.getAttribute("number"));
            setMetadataOpen(true);
            const metadataAnchor = document.getElementById("metadata_anchor");
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const metadataAnchorWidth = metadataAnchor.offsetWidth;
            const metadataAnchorHeight = metadataAnchor.offsetHeight;
            document.getElementById("metadata_anchor").style.position =
              "absolute";
            document.getElementById("metadata_anchor").style.left =
              clickX + "px";
            document.getElementById("metadata_anchor").style.top =
              clickY + "px";
            //find click position such that by comparing width of popper and height of popper
            if (clickX + metadataAnchorWidth > viewportWidth) {
              // Adjust position to the left of the click point
              setMetadataPlacement("left");
            } else {
              setMetadataPlacement("right");
            }

            // Check if the element would overflow the bottom edge of the viewport
            if (clickY + metadataAnchorHeight > viewportHeight) {
              // Adjust position to the top of the click point
              setMetadataPlacement("top");
            } else {
              setMetadataPlacement("bottom");
            }
            setMetadataAnchorEl(document.getElementById("metadata_anchor"));
          }
        });
      },
    });
  }

  async function loadImage(selectedRef) {
    if (edit) {
      setConfirmDialogText(
        "Unsaved chnages are lost.Are you sure you want to change the image?"
      );
      const result = await confirm();
      if (!result) {
        return;
      }
    }
    setEdit(false);
    setMetadataOpen(false);
    Array.prototype.slice
      .call(document.getElementsByTagName("a-sphere"))
      .forEach(function (item) {
        item.remove();
      });
    const metaDataPath = selectedRef + "-metadata.json";
    setMetaDataRef(metaDataPath);
    getDownloadURL(ref(storage, metaDataPath))
      .then((url) => {
        // Use the URL to fetch the JSON data
        return fetch(url);
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((jsonData) => {
        // Use jsonData as your JSON data
        setData(jsonData);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setMessage("No Image Found");
        handleSnackClick();
        setOpen(false);
      });
    setMessage("Loading Image!");
    handleSnackClick();
    getDownloadURL(ref(storage, selectedRef + ".png"))
      .then(function (url) {
        // `url` is the download URL for 'path/to/your/file'
        // This can be downloaded directly:
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.open("GET", url);
        xhr.send();
        setMessage("Loading Image...");
        xhr.onprogress = function (event) {
          if (event.lengthComputable) {
            var percentComplete = (event.loaded / event.total) * 100;
            if (percentComplete === 100) {
              //create new sky element and replace it wiht curretn sky
              setInHome(false);
              setMessage("Image loaded!");
              handleSnackClick();
              setOpen(false);
            }
          }
        };
        xhr.onload = function (event) {
          const newSky = document.createElement("a-sky");
          newSky.setAttribute("class", "clickable");
          newSky.setAttribute("src", url);
          newSky.setAttribute("rotation", "0 -90 0");
          // Replace the existing <a-sky> element with the new one
          sceneRef.current.replaceChild(newSky, skyRef.current);
          skyRef.current = newSky;
        };
      })
      .catch(function (error) {
        // Handle any errors
        console.error("Error fetching file:", error);
      });
  }

  const handleClose = () => {
    handleSave();
    setCurrentSphereNumber("0");
    setMetadataOpen(false);
  };

  const handleSave = () => {
    // Create new key-value pairs object
    const newKeyValuePairs = {};
    keyValues.forEach((field) => {
      newKeyValuePairs[field.key] = field.value;
    });

    // Ensure newData is a deep copy if necessary
    const newData = { ...data };
    const sphereData = newData[currentSphereNumber];

    if (!sphereData || currentSphereNumber === "0") {
      console.error("Invalid sphere number:", currentSphereNumber);
      return;
    }

    const { intersection } = sphereData;

    // Update sphere data with new key-value pairs
    newData[currentSphereNumber] = {
      intersection: intersection,
      ...newKeyValuePairs,
    };

    // Create metadata array excluding the intersection
    const metadata = Object.keys(newData[currentSphereNumber])
      .filter((key) => key !== "intersection")
      .map((key) => ({
        key: key,
        value: newData[currentSphereNumber][key],
      }));

    // Update state
    setKeyValues(metadata);
    setData(newData); // Update the state with the new data
    setCurrentSphereNumber(""); // Set to empty string to indicate no current sphere selected
    setMetadataOpen(false);
  };

  const handleAddField = () => {
    setKeyValues([...keyValues, { key: "", value: "" }]);
  };

  const handleDeleteField = (index) => {
    setKeyValues(keyValues.filter((_, i) => i !== index));
  };

  const handleSphere = (evt) => {
    if (edit && metadataRef!=null) {
      const eventType = evt.detail.mouseEvent.button;
      if (eventType === 1) {
        const spheres = document.getElementsByTagName("a-sphere");
        const usedNumbers = new Set();
        // Collect all used numbers, skipping 0
        for (let i = 0; i < spheres.length; i++) {
          const num = parseInt(spheres[i].getAttribute("number"), 10);
          if (!isNaN(num) && num !== 0) {
            usedNumbers.add(num);
          }
        }
        // Find the next available number, skipping 0
        let num = 1;
        while (usedNumbers.has(num)) {
          num++;
        }
        const intersection = evt.detail.intersection;
        if (intersection && intersection.point) {
          const sphereEl = document.createElement("a-sphere");
          // draw a vector ferom intesection point to camera and move the intersection point by 10 units towards camera
          const camera = document.querySelector("a-camera");
          const cameraPosition = camera.getAttribute("position");
          const cameraVector = new window.THREE.Vector3(
            cameraPosition.x,
            cameraPosition.y,
            cameraPosition.z
          );
          const intersectionVector = new window.THREE.Vector3(
            intersection.point.x,
            intersection.point.y,
            intersection.point.z
          );
          const directionVector = cameraVector.sub(intersectionVector);
          directionVector.normalize();
          intersection.point.x += directionVector.x * 10;
          intersection.point.y += directionVector.y * 10;
          intersection.point.z += directionVector.z * 10;
          if (sphereEl) {
            sphereEl.setAttribute("position", intersection.point);
            sphereEl.setAttribute("color", "black");
            sphereEl.setAttribute("class", "clickable");
            sphereEl.setAttribute("spheres", true);
            sphereEl.setAttribute("number", num);
            sphereEl.setAttribute("radius", 5);

            // Append the sphere to the scene
            if (sceneRef.current) {
              sceneRef.current.appendChild(sphereEl);
            }

            // Update data using functional form to ensure the latest state is used
            setData((prevData) => {
              const newData = { ...prevData };
              const meta = {
                intersection: {
                  x: intersection.point.x,
                  y: intersection.point.y,
                  z: intersection.point.z,
                },
              };
              newData[num] = meta;
              return newData;
            });
          }
        }
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // skyRef.current.setAttribute("src", interia_lobby);
    if (theme.palette.mode == "light") {
      skyRef.current.setAttribute("color", "#696969");
    } else if (theme.palette.mode == "dark") {
      skyRef.current.setAttribute("color", "#000000");
    }
  }, []);

  const handleDrop = async (event) => {
    if (event) event.preventDefault();
    var viewID = event.dataTransfer.getData("viewID");
    if (viewID === "") return;
    if (edit) {
      setConfirmDialogText(
        "Unsaved chnages are lost.Are you sure you want to change the image?"
      );
      const result = await confirm();
      if (!result) {
        return;
      }
    }
    var selectedRef = URL.baseURL + "/" + auth.currentUser.email + "/" + viewID;
    loadImage(selectedRef);
  };

  const handleDeleteSphere = () => {
    var newData = data;
    delete newData[currentSphereNumber];
    setData(newData);
    var sphere = document.querySelector(
      'a-sphere[number="' + currentSphereNumber + '"]'
    );
    sphere.parentNode.removeChild(sphere);
    setCurrentSphereNumber("0");
    setMetadataOpen(false);
  };

  return (
    <div onDragOver={handleDragOver} onDrop={handleDrop}>
      <div>
        <div id="metadata_anchor">Ref</div>
        <Popper
          id="metadata_form"
          sx={{ zIndex: 1000 }}
          open={metadataOpen}
          anchorEl={metadataAnchorEl}
          placement={metadataPlacement}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Box
              sx={{
                p: 1,
                borderRadius: 2,
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
              }}
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              {keyValues.map((field, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <TextField
                    type="text"
                    label="Key"
                    variant="standard"
                    value={field.key}
                    onChange={(e) =>
                      setKeyValues(
                        keyValues.map((item, i) =>
                          i === index ? { ...item, key: e.target.value } : item
                        )
                      )
                    }
                  />
                  <TextField
                    type="text"
                    label="Value"
                    value={field.value}
                    variant="standard"
                    onChange={(e) =>
                      setKeyValues(
                        keyValues.map((item, i) =>
                          i === index
                            ? { ...item, value: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                  <IconButton onClick={() => handleDeleteField(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              ))}
              <Button
                type="button"
                variant="contained"
                onClick={handleAddField}
              >
                Add Field
              </Button>

              <Button type="button" variant="contained" onClick={handleClose}>
                Close
              </Button>
              <Button
                type="button"
                variant="contained"
                onClick={handleDeleteSphere}
              >
                Delete Sphere
              </Button>
              {/* <Button type="submit">Save</Button> */}
            </Box>
          </ClickAwayListener>
        </Popper>
        {dialog}
      </div>
      <a-scene
        ref={sceneRef}
        link-controls
        onContextMenu={(e) => e.preventDefault()}
      >
        <a-camera cam ref={cameraRef} wasd-controls="acceleration: 0">
          <a-entity
            ref={cursorRef}
            cursor="rayOrigin:mouse"
            raycaster="objects: .clickable; far: 1000;"
          ></a-entity>

          {!skyRef.current || !skyRef.current.getAttribute("src") ? (
            <a-image src={interiaLogo} position="0 0 -5"></a-image>
          ) : null}
        </a-camera>
        <a-sky
          ref={skyRef}
          class="clickable"
          onMouseUp={handleSphere}
          // src={interia_lobby}
          color="#696969"
          rotation="0 -90 0"
        ></a-sky>
        <style>
          {`
  .a-enter-vr-button {
   display: none;
  }
`}
        </style>
  

      </a-scene>
    </div>
  );
};

export default ImageViewer;
